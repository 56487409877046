import { createApp } from 'vue'
import VueYandexMetrika from 'vue-v3-yandex-metrika'
import App from './App.vue'
import router from './router'
import store from './store/store'
import i18n from 'shared/src/i18n'
import axios from 'axios'
import { loadLanguageAsync } from '@/helpers/load-languages-async'
import SmUi from 'smarket-ui/src/main'
import SvgIcon from 'shared/src/components/SvgIcon'

axios.defaults.baseURL = 'https://api.smarket.hk/api/v1/'
//axios.defaults.baseURL = 'http://api.stage01.umko/api/v1/'
//axios.defaults.baseURL = 'http://temp.smarket.mx/api/v1/'
router.beforeEach((to, from, next) => {
  const lang = router?.app?.$store?.getters?.locale || 'en'
  loadLanguageAsync(lang)
    .then(() => next())
    .catch(error => {
      store.dispatch('showError', error)
      next()
    })
})

const app = createApp(App)

app.use(i18n)
app.use(router)
app.use(VueYandexMetrika, {
  id: 84464458,
  env: process.env.NODE_ENV,
  router,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
})
app.use(store)
app.use(SmUi)

app.component('svg-icon', SvgIcon)

app.mount('#app')
