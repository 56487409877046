// please, add components in alphabetical order
import BaseButton from './components/buttons/BaseButton'
import BaseInput from './components/forms/BaseInput'
import BaseSelect from './components/select/BaseSelect'
import InputWithLabel from './components/forms/InputWithLabel'
import LoginForm from './components/forms/LoginForm'
import SimpleButton from './components/buttons/SimpleButton'
import TooltipIcon from './components/forms/TooltipIcon'

// please, add components in alphabetical order
export default {
  BaseButton,
  BaseInput,
  BaseSelect,
  InputWithLabel,
  LoginForm,
  SimpleButton,
  TooltipIcon,
}
