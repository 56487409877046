<template>
  <div
    :class="overlayType"
    class="overlay"
    v-if="loadingStatus !== 'loading' && overlayStatus"
    @click="clickOnOverlay"
  >
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TheOverlay',
  computed: {
    ...mapState({
      loadingStatus: state => state.ui.status,
      overlayStatus: state => state.ui.overlay,
      overlayType: state => state.ui.overlayType,
    }),
  },
  methods: {
    clickOnOverlay () {
      this.$store.commit('setOverlayStatus', !this.overlayStatus)
      if (this.overlayStatus) {
        this.$store.dispatch('hideScrollableOverlay')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;

    &.invisible {
      background-color: transparent;
    }
  }
</style>
