<template>
  <svg
    :class="svgClass"
    :style="baseStyles"
    aria-hidden="true"
    @click="$emit('click')"
  >
    <use :href="name"></use>
  </svg>
</template>

<script>
// https://webpack.js.org/guides/dependency-management/#context-module-api
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('../assets/svg-icons', false, /\.svg$/)
requireAll(req)

export default {
  name: 'SvgIcon',
  props: {
    iconName: {
      // icon filename
      type: String,
      required: true,
    },
    className: {
      // css class name
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '1em',
    },
    height: {
      type: String,
      default: '1em',
    },
  },
  computed: {
    name () {
      let icon = this.iconName
      return icon ? `#icon-${icon}` : ''
    },
    baseStyles () {
      return {
        width: this.width,
        height: this.height,
      }
    },
    svgClass () {
      let className = this.className
      return className ? `svg-icon ${className}` : 'svg-icon'
    },
  },
}
</script>

<style lang="scss">
.svg-icon {
  fill: currentColor;
  overflow: hidden;
}

.login-wrap .svg-icon {
  fill: $color-grey-light;
}

.login-wrap .__error .svg-icon {
  fill: $color-error;
}

.user-profile--contacts .svg-icon {
  fill: $color-primary;
  margin-right: 10px;
}

.edit-variation--edit-icon {
  fill: $color-primary;
  opacity: 0;
}

.edit-variation--text:hover .edit-variation--edit-icon {
  opacity: 1;
}

.bad-price.svg-icon {
  fill: $color-error-secondary;
}

.svg-icon.good-price,
.svg-icon.unique-price {
  fill: $color-primary;
}

</style>
