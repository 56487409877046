export const parseFile = (path) => {
  const getExtname = (path) => {
    const split = path.split('.')
    return split[split.length - 1]
  }
  const getFileName = (path) => {
    const split = path.split('/')
    return split[split.length - 1]
  }

  return {
    extname: getExtname(path),
    name: getFileName(path),
    path,
  }
}

export class BillingListItem {
  paymentNumber = ''
  finishedAt = ''
  paymentInterval = ''
  payments = ''
  orderCount = ''
  comment = ''
  totalSum = ''
  files = []

  constructor (item) {
    Object.assign(this, item)
    this.parseFiles()
  }

  parseFiles () {
    this.files = this.files.map(parseFile)
  }
}

export class BillingPayment {
  paymentNumber = ''
  createdAt = ''
  paymentInterval = ''
  comment = ''
  totalSum = ''
  files = []
  orders = []

  constructor (payment) {
    Object.assign(this, payment)
    this.orders.forEach(item => {
      item.showItems = false
    })
  }

  showItem (order) {
    const state = order.showItems
    this.orders.forEach(item => {
      item.showItems = false
    })
    order.showItems = !state
  }
}
