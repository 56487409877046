import { PRODUCT_STATUS_ALL } from '../../helpers/consts/product-statuses'
import api from '@/api/api'

const state = {
  productStatus: PRODUCT_STATUS_ALL,
  expandAllModels: true,
  modelsOnPage: 20,
  models: [],
  filteredModels: [],
  statuses: [],
  query: '',
  pagination: {
    page: 1,
    per_page: 20,
  },
}
const actions = {
  async getModels ({ commit, dispatch }, payload) {
    try {
      commit('setAuthorizationHeader')
      const response = await api.getModels(payload)
      commit('setModels', response.data.items)
      commit('setModelsPagination', response.data.pagination)
      if (response.data.items && response.data.items.length) {
        dispatch('changeModelView', response.data.items[0].id)
      }
      dispatch('filterModels')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  changeModelView ({ commit, dispatch, state }, id) {
    const models = JSON.parse(JSON.stringify(state.models))
    const modified = models.map(model => {
      if (model.id === id) {
        model.showBody = !model.showBody
      } else {
        model.showBody = false
      }
      return model
    })

    commit('setModels', modified)
  },

  async getModelStatusesWithCount ({ commit, dispatch }) {
    try {
      const response = await api.getModelStatusesWithCount()
      commit('setModelsStatuses', response.data || [])
    } catch (error) {
      throw error
    }
  },

  addProductToModel ({ commit, dispatch }, modelId) {},

  async updateProduct ({ commit, dispatch }, payload) {
    try {
      const response = await api.updateProduct(payload)
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  productStatusChanged ({ commit, dispatch }, status) {
    commit('setProductStatus', status)
    dispatch('filterModels')
  },

  filterModels ({ commit, state }) {
    const models = JSON.parse(JSON.stringify(state.models))
    const filteredModels = models.map(model => {
      model.products = model.products.filter(product => {
        switch (state.productStatus) {
          case PRODUCT_STATUS_ALL:
            return true
          default:
            return product.status === state.productStatus
        }
      })

      return model
    })

    commit('setFilteredModels', filteredModels)
  },

  toggleModels ({ commit, dispatch }, value) {
    commit('setExpandAllModels', value)
  },

  updateQuery ({ commit, dispatch }, query) {
    commit('setQuery', query)
  },
}

const mutations = {
  setModels (state, models) {
    state.models = models
  },

  setModelsPagination (state, pagination) {
    state.pagination = pagination
  },

  setModelsStatuses (state, statuses) {
    state.statuses = statuses
  },

  setExpandAllModels (state, value) {
    state.expandAllModels = value
  },

  setProductStatus (state, status) {
    state.productStatus = status
  },

  setFilteredModels (state, models) {
    state.filteredModels = models
  },

  setQuery (state, query) {
    state.query = query
  },
}

export default {
  state,
  actions,
  mutations,
}
