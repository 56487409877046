
import components from './index'

const SmUi = {
  install (app, options = {}) {
    for (const componentName in components) {
      const component = components[componentName]

      app.component(component.name, component)
    }
  },
}

export default SmUi
