<template>
  <div class="login">
    <Form
      v-slot="{ meta }"
      as="div"
      class="login__form form"
    >
      <div class="form__title">
        {{ welcomeText }}

        <span class="form__error-msg" v-if="loginErrorMsg.length > 0">{{loginErrorMsg}}</span>
      </div>
      <input-with-label-validation
        class="form__field-wrap"
        rules="required|email"
        :customMessages="{ required: validateEmailMsgRequired, email: validateEmailMsgEmail }"
        iconName="mail"
        v-model="email"
        name="email"
        type="email"
        :placeholder="placeholderEmail"
        @input="clearErrorMsg"
      />
      <input-with-label-validation
        class="form__field-wrap"
        rules="required|min:6"
        :customMessages="{ required: validatePassMsg, min: minPassLength}"
        v-model="password"
        iconName="pass"
        name="password"
        type="password"
        :placeholder="placeholderPass"
        @input="clearErrorMsg"
      />
      <simple-button
        class="form__submit"
        :value="loginText"
        :disabled="!meta.valid || loginDisabled"
        @click="login"
      />

      <div class="form-note" v-if="hasRegistration">
        {{ noAcc }}
        <router-link
          class="form-note__link text-14-700"
          :to="registrationLink"
        >{{ registrationText }}</router-link>
      </div>
    </Form>
  </div>
</template>

<script>

import { Form } from 'vee-validate'
import InputWithLabelValidation from './InputWithLabelValidation'

export default {
  name: 'login-form',
  components: {
    InputWithLabelValidation,
    Form,
  },
  props: {
    validateEmailMsgRequired: {
      type: String,
    },
    validateEmailMsgEmail: {
      type: String,
    },
    placeholderEmail: {
      type: String,
    },
    validatePassMsg: {
      type: String,
    },
    minPassLength: {
      type: String,
    },
    placeholderPass: {
      type: String,
    },
    registrationLink: {
      type: String,
    },
    newUserEmail: {
      type: String,
      default: '',
    },
    emailConfirmed: {
      type: String,
      default: '0',
    },
    loginText: {
      type: String,
    },
    registrationText: {
      type: String,
    },
    noAcc: {
      type: String,
    },
    welcomeText: {
      type: String,
    },
    needConfirmText: {
      type: String,
    },
    hasRegistration: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      email: '',
      password: '',
      loginErrorMsg: '',
    }
  },
  computed: {
    emailConfirmedBoolean () {
      return !!Number(this.emailConfirmed)
    },
    needConfirmEmail () {
      return this.newUserEmail !== '' && this.emailConfirmedBoolean === false
    },
    loginDisabled () {
      return this.loginErrorMsg.length > 0
    },
  },
  mounted () {
    if (this.needConfirmEmail) {
      this.loginErrorMsg = this.needConfirmText
      this.email = this.newUserEmail
      this.$emit('showNeedConfirmMessage')
    } else if (this.emailConfirmedBoolean) {
      this.$emit('showConfirmedMessage')
    }
  },
  methods: {
    async login () {
      const { email, password } = this
      this.$emit('login', {
        email,
        password,
      })
    },
    clearErrorMsg () {
      this.loginErrorMsg = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/style";

.login {
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  max-width: 560px;
  padding: 40px 80px;
  box-shadow: 0 0 24px 0 #0000000F;
  border-radius: 20px;
  flex: 1 0 auto;

  &__title {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    font-size: 32px;
    font-weight: 800;
    text-align: center;
  }

  &__error-msg {
    color: $color-error;
    font-size: 12px;
  }

  &__field-wrap {
    position: relative;
    margin-bottom: 20px;
  }

  &__submit {
    width: 100%;
    margin-bottom: 21px;
    font-size: 16px;
  }
}
.form-note {
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__link {
    margin-top: 5px;
    text-decoration: none;
  }
}
</style>
