<template>
  <div
    class="overlay"
    v-if="loadingStatus === 'loading'">
    <sm-loader class="overlay-loader"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SmLoader from './SmLoader'

export default {
  name: 'TheLoader',
  components: {
    SmLoader,
  },
  computed: {
    ...mapState({ loadingStatus: state => state.ui.status }),
  },
}
</script>

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 90;
  }
  .overlay-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    z-index: 99;
  }
</style>
