<template>
  <div class="sm-loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>

    <svg viewBox="0 0 76 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.78145 15.9559C6.16938 15.9559 5.69642 15.7793 5.36256 15.4262C5.02871 15.0584 4.86178 14.5508 4.86178 13.9034V3.50897H1.58582C1.07112 3.50897 0.674667 3.3692 0.396454 3.08966C0.132151 2.7954 0 2.38345 0 1.85379C0 1.30943 0.132151 0.897471 0.396454 0.617931C0.674667 0.338391 1.07112 0.198621 1.58582 0.198621H11.9771C12.4918 0.198621 12.8813 0.338391 13.1456 0.617931C13.4238 0.897471 13.5629 1.30943 13.5629 1.85379C13.5629 2.38345 13.4238 2.7954 13.1456 3.08966C12.8813 3.3692 12.4918 3.50897 11.9771 3.50897H8.70112V13.9034C8.70112 14.5508 8.54115 15.0584 8.2212 15.4262C7.90126 15.7793 7.42134 15.9559 6.78145 15.9559Z" fill="#8624C6"/>
      <path d="M16.7852 16C15.8949 16 15.1646 15.8308 14.5943 15.4924C14.024 15.1393 13.5927 14.617 13.3006 13.9255C13.0224 13.234 12.8833 12.3586 12.8833 11.2993V6.66483C12.8833 6.01747 13.0433 5.53195 13.3632 5.20828C13.6832 4.86988 14.1422 4.70069 14.7404 4.70069C15.3385 4.70069 15.7976 4.86988 16.1175 5.20828C16.4375 5.53195 16.5974 6.01747 16.5974 6.66483V11.4097C16.5974 11.9393 16.7018 12.3366 16.9104 12.6014C17.1191 12.8662 17.446 12.9986 17.8911 12.9986C18.3919 12.9986 18.8023 12.8074 19.1222 12.4248C19.4422 12.0423 19.6021 11.5347 19.6021 10.9021V6.66483C19.6021 6.01747 19.7621 5.53195 20.0821 5.20828C20.402 4.86988 20.8611 4.70069 21.4592 4.70069C22.0574 4.70069 22.5164 4.86988 22.8364 5.20828C23.1563 5.53195 23.3163 6.01747 23.3163 6.66483V13.9697C23.3163 15.2938 22.7181 15.9559 21.5218 15.9559C20.9376 15.9559 20.4855 15.7867 20.1655 15.4483C19.8456 15.1099 19.6856 14.617 19.6856 13.9697V12.6897L20.0195 13.771C19.7134 14.492 19.2822 15.0437 18.7258 15.4262C18.1833 15.8087 17.5364 16 16.7852 16Z" fill="#8624C6"/>
      <path d="M27.3765 15.9559C26.7644 15.9559 26.2915 15.7867 25.9576 15.4483C25.6377 15.1099 25.4777 14.617 25.4777 13.9697V6.66483C25.4777 6.03218 25.6307 5.54667 25.9368 5.20828C26.2567 4.86988 26.7088 4.70069 27.2931 4.70069C27.8773 4.70069 28.3224 4.86988 28.6285 5.20828C28.9345 5.54667 29.0875 6.03218 29.0875 6.66483V7.56966H28.8789C29.018 6.6869 29.3727 5.98805 29.943 5.4731C30.5273 4.95816 31.2506 4.68598 32.1131 4.65655C32.5165 4.64184 32.8156 4.75218 33.0103 4.98759C33.219 5.20828 33.3303 5.62759 33.3442 6.24552C33.3442 6.77517 33.2399 7.19448 33.0312 7.50345C32.8225 7.81241 32.4261 7.99632 31.8418 8.05517L31.3619 8.09931C30.6247 8.17287 30.0891 8.39356 29.7552 8.76138C29.4214 9.11448 29.2545 9.64414 29.2545 10.3503V13.9697C29.2545 14.617 29.0945 15.1099 28.7745 15.4483C28.4546 15.7867 27.9886 15.9559 27.3765 15.9559Z" fill="#8624C6"/>
      <path d="M41.2857 16C40.4928 16 39.7833 15.8014 39.1573 15.4041C38.5453 14.9922 38.1418 14.4552 37.9471 13.7931L38.1766 12.8883V13.9697C38.1766 14.617 38.0167 15.1099 37.6967 15.4483C37.3907 15.7867 36.9455 15.9559 36.3613 15.9559C35.7631 15.9559 35.3041 15.7867 34.9841 15.4483C34.6781 15.1099 34.5251 14.617 34.5251 13.9697V1.98621C34.5251 1.33885 34.6851 0.845977 35.005 0.507586C35.3249 0.169195 35.777 0 36.3613 0C36.9594 0 37.4185 0.169195 37.7384 0.507586C38.0723 0.845977 38.2392 1.33885 38.2392 1.98621V6.70896H37.9888C38.1975 6.10575 38.6079 5.61287 39.2199 5.23034C39.832 4.84782 40.5206 4.65655 41.2857 4.65655C42.1759 4.65655 42.9549 4.8846 43.6227 5.34069C44.3043 5.79678 44.8329 6.44414 45.2085 7.28276C45.5841 8.12138 45.7719 9.13655 45.7719 10.3283C45.7719 11.4906 45.5841 12.4984 45.2085 13.3517C44.8329 14.2051 44.3043 14.8598 43.6227 15.3159C42.9549 15.772 42.1759 16 41.2857 16ZM40.1172 13.109C40.4928 13.109 40.8197 13.0133 41.0979 12.8221C41.39 12.6161 41.6195 12.3145 41.7864 11.9172C41.9534 11.5053 42.0368 10.9756 42.0368 10.3283C42.0368 9.34253 41.856 8.63632 41.4943 8.20966C41.1326 7.76828 40.6736 7.54759 40.1172 7.54759C39.7416 7.54759 39.4077 7.64322 39.1156 7.83448C38.8235 8.02575 38.5939 8.32736 38.427 8.73931C38.2601 9.13655 38.1766 9.66621 38.1766 10.3283C38.1766 11.2993 38.3575 12.0055 38.7191 12.4469C39.0808 12.8883 39.5468 13.109 40.1172 13.109Z" fill="#8624C6"/>
      <path d="M52.6601 16C51.5194 16 50.5248 15.772 49.6762 15.3159C48.8277 14.8598 48.1669 14.2051 47.694 13.3517C47.221 12.4984 46.9845 11.4906 46.9845 10.3283C46.9845 9.44552 47.1167 8.65839 47.381 7.9669C47.6453 7.26069 48.0278 6.66483 48.5286 6.17931C49.0294 5.67908 49.6275 5.30391 50.3231 5.05379C51.0186 4.78897 51.7976 4.65655 52.6601 4.65655C53.8008 4.65655 54.7954 4.8846 55.6439 5.34069C56.4925 5.79678 57.1463 6.45149 57.6053 7.30483C58.0783 8.14345 58.3148 9.15127 58.3148 10.3283C58.3148 11.1963 58.1826 11.9834 57.9183 12.6897C57.654 13.3959 57.2715 13.9991 56.7707 14.4993C56.2838 14.9848 55.6856 15.36 54.9762 15.6248C54.2807 15.8749 53.5086 16 52.6601 16ZM52.6601 13.109C53.0357 13.109 53.3695 13.0133 53.6616 12.8221C53.9538 12.6161 54.1763 12.3145 54.3294 11.9172C54.4963 11.5053 54.5797 10.9756 54.5797 10.3283C54.5797 9.34253 54.3989 8.63632 54.0372 8.20966C53.6756 7.76828 53.2165 7.54759 52.6601 7.54759C52.2845 7.54759 51.9506 7.64322 51.6585 7.83448C51.3664 8.02575 51.1369 8.32736 50.9699 8.73931C50.803 9.13655 50.7195 9.66621 50.7195 10.3283C50.7195 11.2993 50.9004 12.0055 51.2621 12.4469C51.6237 12.8883 52.0897 13.109 52.6601 13.109Z" fill="#8624C6"/>
      <path d="M62.0406 15.9559C61.4842 15.9559 61.053 15.794 60.747 15.4703C60.4548 15.1467 60.3088 14.6906 60.3088 14.1021V1.85379C60.3088 1.25058 60.4687 0.794483 60.7887 0.485517C61.1086 0.161839 61.5607 0 62.145 0C62.6319 0 63.0144 0.102989 63.2926 0.308966C63.5847 0.50023 63.849 0.823909 64.0855 1.28L68.5717 9.79862H67.8414L72.3067 1.28C72.5571 0.823909 72.8214 0.50023 73.0996 0.308966C73.3918 0.102989 73.7812 0 74.2681 0C74.8246 0 75.2488 0.161839 75.541 0.485517C75.847 0.794483 76 1.25058 76 1.85379V14.1021C76 14.6906 75.8539 15.1467 75.5618 15.4703C75.2697 15.794 74.8385 15.9559 74.2681 15.9559C73.7117 15.9559 73.2805 15.794 72.9744 15.4703C72.6823 15.1467 72.5362 14.6906 72.5362 14.1021V6.51035H72.9744L69.615 12.6897C69.4202 13.0133 69.2116 13.2561 68.989 13.4179C68.7804 13.5798 68.4952 13.6607 68.1335 13.6607C67.7858 13.6607 67.5006 13.5798 67.278 13.4179C67.0554 13.2561 66.8537 13.0133 66.6729 12.6897L63.2926 6.48828H63.7725V14.1021C63.7725 14.6906 63.6265 15.1467 63.3343 15.4703C63.0422 15.794 62.611 15.9559 62.0406 15.9559Z" fill="#8624C6"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: 'sm-loader',
}
</script>

<style scoped lang="scss">
.sm-loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  box-sizing: border-box;
}
.sm-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 5px;
  border: 3px solid;
  border-radius: 50%;
  animation: sm-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-primary transparent transparent transparent;
}
.sm-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.sm-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.sm-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes sm-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
