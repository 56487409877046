<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="footer__content">
        <div class="footer__column">
          <router-link class="footer__logo" to="/">
            <img src="@/assets/Logo.svg" />
          </router-link>
          <a href="https://smarket.hk/" class="footer__text footer__link-inner">
            {{ $t('about') }}
          </a>
          <router-link
            v-if="true"
            to="/help-center"
            class="help-center-link header__link"
          >
            <img
              class="help-center-link--icon"
              src="@/assets/question.svg"
            />
            {{ $t('helpCenterLink') }}
          </router-link>
        </div>
        <div class="footer__column footer__column_middle">
          <div class="footer__title">{{ $t('anyQuestions') }}</div>
          <div class="footer__text">{{ $t('contactUs') }}</div>
          <svg-icon class="footer__mail-icon" icon-name="mail-lines" />
          <div>
            <a class="footer__link" :href="`mailto:${emailSupport}`">
              {{ emailSupport }}
            </a>
          </div>
        </div>
        <div class="footer__column">
          <div class="footer__title">{{ $t('focus') }}</div>
          <img class="qr-code" src="@/assets/qr-code.jpg" />
        </div>
      </div>
      <div class="footer__bottom">
        <p class="footer__bottom-text">{{ $t('license') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'TheFooter',
  data () {
    return {
      emailSupport: 'seller@smarket.hk',
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background: $color-grey;

  .help-center-link {
    color: #000;
    text-decoration: none;
  }
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 51px 0 0 0;
}

.footer__content {
  display: flex;
  height: 183px;
}

.footer__column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.33%;
  padding: 10px 0 0 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__column_middle {
  border-left: solid 1px $footer-column-border-color;
  border-right: solid 1px $footer-column-border-color;
}

.footer__logo {
  margin: 0 0 22px 0;
}

.footer__text {
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto 12px auto;
  color: #000;
}

.footer__title {
  font-size: 20px;
  font-weight: 800;
  margin: 0 auto 30px auto;
  text-transform: uppercase;
}

.footer__link {
  color: $text-gray;
  font-size: 14px;
  text-decoration: none;
}

.footer__link-inner {
  text-decoration: none;
  border-bottom: dashed 1px $text-gray;
  cursor: pointer;
}

.footer__mail-icon {
  width: 24px;
  color: transparent; /* fix svg image colored background #333 from #app style */
  margin: 0 auto 8px auto;
  stroke: $text-gray;
}

.footer__bottom {
  height: 86px;
  line-height: 86px;
}

.footer__bottom-text {
  font-size: 12px;
  color: $text-gray;
  text-align: center;
}

.qr-code {
  width: 124px;
  height: 124px;
}
</style>
