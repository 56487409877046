import axios from 'axios'

class Api {
  contentTypeDefault = 'application/json'

  async _request ({ url, method, headers, params, data, responseType }) {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('api_token')}`
      headers = this._setContentType(headers)
      data = this._prepareData(headers, data)
      return await axios({ url, method, headers, params, data, responseType })
    } catch (error) {
      throw error
    }
  }

  /**
   * set headers Content-Type by default if not exist
   * @param {object} headers
   * @returns
   */
  _setContentType (headers) {
    const headersPrepared = headers || { 'Content-Type': this.contentTypeDefault }
    headersPrepared['Content-Type'] = headersPrepared['Content-Type'] || this.contentTypeDefault
    return headersPrepared
  }

  /**
   * format data object by Content-Type
   * @param {object} headers
   * @param {object} data
   * @returns
   */
  _prepareData (headers, data) {
    let dataPrepared = data
    if (data && headers['Content-Type'] === 'multipart/form-data') {
      dataPrepared = new FormData()
      for (const key in data) { dataPrepared.append(key, data[key]) }
    }
    if (data && headers['Content-Type'] === 'application/json') {
      dataPrepared = JSON.stringify(dataPrepared)
    }
    return dataPrepared
  }

  async _basicGet (url, params) {
    try {
      return await this._request({
        url,
        method: 'GET',
        params,
      })
    } catch (error) {
      throw error
    }
  }

  async _basicPost (url, data) {
    try {
      return await this._request({
        url,
        method: 'POST',
        data,
      })
    } catch (error) {
      throw error
    }
  }

  async _basicPut (url, data) {
    try {
      return await this._request({
        url,
        method: 'PUT',
        data,
      })
    } catch (error) {
      throw error
    }
  }

  async registration (payload) {
    return this._basicPost('register', payload)
  }

  async login (payload) {
    try {
      return await this._request({
        url: 'login',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: payload,
      })
    } catch (error) {
      throw error
    }
  }

  async getAllCategories () {
    return this._basicGet('/category/all')
  }
}

export default Api
