import i18n from 'shared/src/i18n'
import axios from 'axios'
import api from '../api/api'

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang = 'en') {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return api.getLanguage(lang)
    .then(res => {
      i18n.global.setLocaleMessage(lang, res.data)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    })
    .catch(error => {
      throw error
    })
}
