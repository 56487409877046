<!--suppress HtmlUnknownTarget -->
<template>
  <transition
    name="show"
  >
    <div
      v-if="isVisible"
      class="toast"
    >
      <div class="toast__icon">
        <img v-if="type === 'SUCCESS'" src="@/assets/check.svg" alt=""/>
        <img v-if="type === 'ERROR'"  src="@/assets/error.svg" alt=""/>
      </div>
      <div class="toast__message">
        <div>
          <div
            v-if="hintNotification.title"
            class="toast__message-title">
            {{ hintNotification.title }}
          </div>
          <div
            v-if="hintNotification.description"
            class="toast__message-description">
            {{ hintNotification.description }}
          </div>
        </div>
      </div>
      <div class="toast__close-block">
        <div
          class="toast__close-wrap"
          @click="close()">
          <svg-icon class="toast__close-button" icon-name="close"></svg-icon>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvgIcon from '@shared/components/SvgIcon'

export default {
  name: 'HintNotification',
  components: {
    SvgIcon,
  },
  props: {
    hintNotification: Object,
  },
  data () {
    return {
      closeTimeout: null,
      types: ['ERROR', 'SUCCESS'],
      isVisible: true,
      timeout: this.hintNotification.timeout || 5000,
    }
  },
  computed: {
    type () {
      return this.types.includes(this.hintNotification.type)
        ? this.hintNotification.type : 'SUCCESS'
    },
  },
  methods: {
    close () {
      this.isVisible = false
      this.$store.commit('removeHintNotificationById', this.hintNotification.id)
    },
  },
  created () {
    this.closeTimeout = setTimeout(() => {
      this.isVisible = false
      this.$store.commit('removeHintNotificationById', this.hintNotification.id)
      clearTimeout(this.closeTimeout)
    }, this.timeout)
  },
}
</script>

<style lang="scss" scoped>
.toast {
  position: relative;
  background: #191C1F;
  border-radius: 16px;
  box-shadow: 0 2px 10px 0 #0000001A;
  margin-bottom: 20px;
  display: flex;
  color: #fff;
  padding: 20px 32px 20px 20px;

  &__message {
    display: flex;
    align-items: center;
    padding-left: 8px;
    min-width: 228px;
    max-height: 66px;
    font-size: 16px;
  }

  &__message-title {
    font-weight: 700;
  }

  &__message-description {
    margin: 4px 0 0 0;
  }

  &__close-wrap {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    text-align: center;
  }

  &__close-button {
    width: 10px;
    height: 10px;
    vertical-align: middle;
  }
}

.show-enter,
.show-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.show-enter-active,
.show-leave-active {
  transition: all 0.3s ease;
}
</style>
