<template>
  <simple-button
    @click="$emit('click')"
    :value="value"
    :icon="icon"
    :disabled="disabled"
    :transparent="true"
    :secondary="true"
  />
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: [
    'disabled',
    'value',
    'icon',
    'transparent',
  ],
}
</script>

<style scoped>

</style>
