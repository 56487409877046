export const PROFILE_STATUS_NEW = 'new'
export const PROFILE_STATUS_ENABLED = 'enabled'
export const PROFILE_STATUS_PROCESSING = 'processing'
export const PROFILE_STATUS_APPROVED = 'approved'
export const PROFILE_STATUS_FAILED = 'failed'
export const PROFILE_STATUS_BLOCKED = 'blocked'

export default {
  PROFILE_STATUS_NEW,
  PROFILE_STATUS_ENABLED,
  PROFILE_STATUS_PROCESSING,
  PROFILE_STATUS_APPROVED,
  PROFILE_STATUS_FAILED,
  PROFILE_STATUS_BLOCKED,
}
