<template>
  <button
    class="base-button button"
    :class="{'base-button--disabled': disabled, 'base-button--transparent': transparent, 'base-button--secondary': secondary, 'warn-button': warn }"
    :disabled="disabled"
    @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'base-button',
  props: [
    'disabled',
    'transparent',
    'secondary',
    'warn',
  ],
}
</script>

<style lang="scss" scoped>
@import "../../styles/style";

.base-button {
  display: inline-flex;
  text-decoration: none;
  background: $color-primary;
  color: $color-white;
  transition: 0.3s ease;
  border-radius: 20px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: $color-primary-hover;
  }

  &:active {
    background: $color-primary-focused;
  }

  &:disabled {
    background: $color-disabled;
    color: #aab4c2;
    cursor: default;

    &:hover {
      background: $color-disabled;
    }

    &:active {
      background: $color-disabled;
    }
  }

  &--transparent {
    background: #fff;
    color: $color-primary-hover;
    transition: 0.3s all ease;
    border: 1px solid $color-primary-hover;

    &:hover {
      color: #fff;
    }

    &:active {
      color: #fff;
    }

    &:disabled {
      background: #fff;
      border-color: $color-disabled;
    }
  }
}

.base-button {
  &--disabled {
    pointer-events: none;
  }

  &--secondary {
    border-color: $color-btn-secondary;
    color: $color-btn-secondary;
    background-color: transparent;
    line-height: 28px;

    &:hover {
      border-color: $color-btn-secondary-hover;
      background-color: transparent;
      color: $color-btn-secondary-hover;
    }
  }

  &--disabled.base-button--secondary {
    border-color: $color-btn-secondary-disabled;
    background-color: transparent;
    color: $color-btn-secondary-disabled;
  }
}

.warn-button {
  @extend .base-button;
  background-color: transparent;
  border: 1px solid $color-error;
  color: $color-error;

  &:hover {
    background-color: $color-error;
    color: #fff;
  }

  &:disabled {
    border-color: $color-disabled;
  }
}
</style>
