<template>
  <div id="app" :class="{ 'no-scroll': !scrollEnable }" :style="appStyles">
    <HeaderNotification v-if="isHeaderMessageVisible"/>
    <HintNotificationsContainer/>
    <ServerErrorPanel/>
    <TheHeader/>
    <div class="content-wrap">
      <div class="content-wrap__inner">
        <router-view v-if="dataLoaded"/>
      </div>
    </div>
    <BaseFooter/>
  </div>
</template>

<script>
import 'reset-css'
import TheHeader from '@/components/ui/layout/TheHeader.vue'
import BaseFooter from '@/components/ui/layout/TheFooter.vue'
import ServerErrorPanel from '@/components/ui/modals/ServerErrorPanel.vue'
import HeaderNotification from './components/ui/HeaderNotification'
import { mapGetters, mapState } from 'vuex'
import {
  PROFILE_STATUS_PROCESSING
} from '@/helpers/consts/profile-statuses'
import { defaultBankCountryCode } from '@/mixins/defaultBankCountryCode'
import HintNotificationsContainer from '@/components/ui/HintNotificationsContainer'

export default {
  components: {
    HintNotificationsContainer,
    HeaderNotification,
    TheHeader,
    BaseFooter,
    ServerErrorPanel,
  },
  mixins: [
    defaultBankCountryCode,
  ],
  data () {
    return {
      interval: null,
      timeInterval: 60000,
      dataLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'isHeaderMessageVisible',
      'authorized',
      'profileStatus',
    ]),
    ...mapState({
      scrollEnable: state => state.ui.scrollEnable,
      appStyles: state => state.ui.appStyles,
    }),
  },
  methods: {
    async reloadProfile () {
      if (this.authorized && this.profileStatus === PROFILE_STATUS_PROCESSING) {
        const profile = await this.$store.dispatch('getProfile')
        if (!profile.bankCountryCode) {
          this.setDefaultBankCountyCode()
        }
      } else {
        clearInterval(this.interval)
      }
    },
  },
  async created () {
    if (this.authorized) {
      try {
        this.$store.commit('setLoadingStatus')
        await this.$store.dispatch('getProfile')
        this.dataLoaded = true
      } finally {
        this.$store.commit('setSuccessStatus')
      }
    } else {
      this.dataLoaded = true
    }

    if (this.authorized && this.profileStatus === PROFILE_STATUS_PROCESSING) {
      this.interval = setInterval(() => {
        this.reloadProfile()
      }, this.timeInterval)
    }
  },
}

</script>

<style lang="scss">
@import "~shared/src/styles/style";

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/roboto-v27-latin-regular.woff2') format('woff2'),
  url('assets/fonts/roboto-v27-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/roboto-v27-latin-italic.woff2') format('woff2'),
  url('assets/fonts/roboto-v27-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('assets/fonts/roboto-v27-latin-500.woff2') format('woff2'),
  url('assets/fonts/roboto-v27-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('assets/fonts/roboto-v27-latin-700.woff2') format('woff2'),
  url('assets/fonts/roboto-v27-latin-700.woff') format('woff');
}

body {
  min-width: 1280px;
  scroll-behavior: smooth;
}
#app {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  box-sizing: border-box;
  .ui.multiple.dropdown>.label i.icon.delete:after, .ui.multiple.dropdown>.label i.icon.delete:before {
    width: 1px;
  }
  .ui.label {
    box-shadow: none;
    background: $color-disabled;
    color: #999999;
    border-radius: 0;
    font-weight: normal;
    border: none;
  }
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content-wrap {
  background: $color-background;
  padding-bottom: 60px;
  box-sizing: border-box;
  min-height: calc(100vh - 380px);
}

.content-wrap__inner {
  max-width: 1920px;
  margin: 0 auto;
}

.main-content {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

.main-content__inner {
  background: $color-white;
  padding: 21px 20px 60px;

  &.__no-bg {
    background-color: transparent;
  }

  &.__registration {
    padding-top: 40px;
  }

  &.__profile {
    padding-top: 30px;
    margin-bottom: 20px;
  }

  &.__no-border {
    border-color: transparent;
  }
}

.main-content__with-sidebar {
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-column-gap: 20px;
}

.main-content__with-sidebar .main-content__inner {
  padding: 30px 57px;
  border: none;
}

.container {
  max-width: 760px;
  margin: 0 auto;
}

.page-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 12px;
}

.page-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: $text-gray;
  margin-bottom: 24px;
}

.form-title {
  position: relative;
  text-align: left;
  margin-bottom: 30px;
  padding-left: 11px;
  font-weight: 700;

  &:before {
    position: absolute;
    content: "";
    width: 3px;
    height: 16px;
    left: 0;
    background: $color-primary;
  }
}

.form-field, .field {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  &_start {
    align-items: flex-start;
  }
  &:last-child {
    margin-bottom: 40px;
  }

  &.__column {
    flex-direction: column;
    align-items: flex-start;
  }
}

.form-field_textarea {
  position: relative;
  align-items: flex-start;
  line-height: 18px;
  .label {
    padding-top: 14px;
  }
}

.form-field__options {
  position: absolute;
  right: 14px;
  color: #999;
  font-size: 14px;
  line-height: 14px;
  bottom: 14px;
}

.general-info__provider_textarea {
  height: 140px;
}

.general-info, .profile-form {
  &__provider {
    position: relative;
  }
}
.form-error {
  position: absolute;
  color: $color-red;
  left: 0;
  top: 52px;
  font-size: 12px;
  line-height: 14px;
  &_textarea {
    top: initial;
    bottom: -18px;
  }
}

::-webkit-input-placeholder {
  @include placeholder;
}

::-moz-placeholder {
  @include placeholder;
}

:-ms-input-placeholder {
  @include placeholder;
}

.readonly {
  pointer-events: none;
  cursor: default;
}

.button-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  &.center {
    justify-content: center;
  }
}

.button {
  display: inline-block;
  padding-left: 35px;
  padding-right: 35px;
}

.base-button {
  display: inline-flex;
  text-decoration: none;
  background: $color-primary;
  color: $color-white;
  transition: 0.3s ease;
  border-radius: 24px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: $color-primary-hover;
  }

  &:active {
    background: $color-primary-focused;
  }

  &:disabled {
    background: $color-disabled;
    color: #aab4c2;
    cursor: default;

    &:hover {
      background: $color-disabled;
    }

    &:active {
      background: $color-disabled;
    }
  }

  &--transparent {
    background: #fff;
    color: $color-primary-hover;
    transition: 0.3s all ease;
    border: 1px solid $color-primary-hover;

    &:hover {
      color: #fff;
    }

    &:active {
      color: #fff;
    }

    &:disabled {
      background: #fff;
      border-color: $color-disabled;
    }
  }
}

.warn-button {
  @extend .base-button;
  background-color: transparent;
  border: 1px solid $color-error;
  color: $color-error;

  &:hover {
    background-color: $color-error;
    color: #fff;
  }

  &:disabled {
    border-color: $color-disabled;
  }
}

.label:not(.ui) {
  width: 115px;
  text-align: left;
  margin-right: auto;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  position: relative;
  &_textarea {
    &.required {
      &:before {
        margin-top: 20px;
      }
    }
  }
  &.required {
    &:before {
      content: '*';
      display: block;
      position: absolute;
      left: -10px;
      top: 5px;
      color: $color-red;
      height: 5px;
      line-height: 12px;
      font-size: 16px;
    }
  }

  &.aligned {
    margin-left: 10px;
    width: calc( 100% - 10px );
  }
}

.input, .vs__dropdown-toggle {
  width: 100%;
  height: 50px;
  padding-left: 14px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid $color-line;
  outline: none;

  &:focus {
    border-color: $color-primary-focused;
  }
}

.vs__dropdown-toggle {
  border-radius: 8px !important;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  background-color: $color-grey !important;
}

.vs--disabled .vs__dropdown-toggle {
  border: 1px solid $color-disabled;
}

.input_select {
  color: #999;
  line-height: 48px;
}

.textarea {
  min-height: 140px;
  padding-top: 13px;
  resize: none;
  line-height: 18px;
}

.found-list {
  position: absolute;
  display: none;
  top: 48px;
  right: 0;
  background: $color-white;
  box-sizing: border-box;
  z-index: 1;
  width: 630px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.arrow {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 11px;
  height: 6px;
  fill: #999;
  transition: 0.3s ease;
}

.field-wrap_active {
  .found-list {
    display: block;
  }

  .arrow {
    transform: rotate(180deg);
  }
}

.form-field_right {
  display: flex;
  justify-content: flex-end;
}

.add-text {
  font-size: 14px;
  color: $color-primary;
}

.message-panel {
  margin: 25px 0;
  padding: 20px;
  background-color: #fff;

  &--content {
    padding: 20px;
    background-color: $color-active-blue;
  }
}

.form-note {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: $text-gray;

  &__link {
    color: $color-primary;
    text-decoration: underline 1px solid $color-primary;
    margin-left: 3px;
    cursor: pointer;
  }
}

.link {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-primary-hover;
  }

  &.__dashed {
    text-decoration: underline dashed;
  }
}

b {
  font-weight: 700;
}

.no-scroll {
  overflow: hidden !important;
}
.divider {
  height: 1px;
  width: 100%;
  background-color: $color-line;
  margin: 30px 0;

  &.__mt10 {
    margin-top: 10px;
  }
}
.align-right {
  text-align: right;
}

.user-profile--notification {
  padding: 16px 20px 24px 20px;
  margin-bottom: 40px;
  background-color: #F0FFF7;
  display: flex;
  align-items: flex-start;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #89DBB2;
  }

  &__processing {
    background-color: $color-active-blue;

    &:after {
      background-color: #E3C6F6;
    }
  }

  &__failed {
    background-color: $color-error-bg;

    &:after {
      background-color: #FFA5A9;
    }
  }

  &-icon {
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  &-title {
    font-weight: 700;
    margin-bottom: 8px;
  }

  &-text {
    padding-top: 4px;
  }
}
</style>
