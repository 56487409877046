import { createI18n } from 'vue-i18n'

import cn from './locales/cn.json';
import en from './locales/en.json';

console.log(en);

const i18n = createI18n({
  locale: '',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    cn: cn,
    en: en,
  },
})

export default i18n
