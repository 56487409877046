import { BillingListItem, BillingPayment } from '@/components/pages/billing/billing.class'
import api from '@/api/api'

const state = {
  payments: [],
  pagination: {},
  payment: {},
}

const actions = {
  async getPayments ({ commit, dispatch }, { params }) {
    try {
      const data = await api.getPayments(params)
      const payments = data.data.items.map(item => new BillingListItem(item))
      const pagination = data.data.pagination

      commit('setPayments', payments)
      commit('setBillingPagination', pagination)
    } catch (error) {
      if (error?.response?.status === 403) {
        commit('setSuccessStatus')
      } else {
        dispatch('showError', error)
        throw new Error(error)
      }
    }
  },

  async getPayment ({ commit, dispatch }, { id }) {
    try {
      const data = await api.getPayment(id)
      const payment = new BillingPayment(data.data)
      commit('setPayment', payment)
    } catch (error) {
      dispatch('showError', error)
      throw new Error(error)
    }
  },

  showPaymentOrder ({ commit, dispatch }, { order }) {
    commit('showOrder', order)
  },

  updatePagination ({ commit }, { pagination }) {
    commit('setBillingPagination', pagination)
  },
}

const mutations = {
  setPayments (store, payments) {
    store.payments = payments
  },
  setPayment (store, payment) {
    store.payment = payment
  },
  showOrder (store, order) {
    store.payment.showItem(order)
  },
  setBillingPagination (store, pagination) {
    store.pagination = pagination
  },
}

export default {
  state,
  actions,
  mutations,
}
