<template>
  <div class="hint-notifications-container">
    <template v-if="notifications && notifications.length > 0">
      <HintNotification
        v-for="notification in notifications"
        :key="notification.id"
        :hintNotification="notification"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HintNotification from './modals/HintNotification'

export default {
  name: 'HintNotificationsContainer',
  components: { HintNotification },
  computed: {
    ...mapState({
      notifications: state => state.ui.hintNotifications,
    }),
  },
}
</script>

<style scoped lang="scss">
.hint-notifications-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: 328px;
  bottom: 60px;
  left: 60px;
  height: calc(100vh - 60px);
  z-index: 999;
}
</style>
