import { createRouter, createWebHistory } from 'vue-router'
import store from './store/store.js'

const checkNotAuthorizedProfile = (to, from, next) => {
  if (!store.getters.authorized) {
    next()
    return
  }
  next('/profile')
}

const checkNotAuthorized = (to, from, next) => {
  if (!store.getters.authorized) {
    next()
    return
  }
  next('/goods/1')
}

const checkUnfinishedValidation = (to, from, next) => {
  if (localStorage.getItem('newUserEmail') !== null) {
    next()
    return
  }
  if (!store.getters.authorized) {
    next('/')
    return
  }
  next('/goods/1')
}

const checkAuthorized = (to, from, next) => {
  if (localStorage.getItem('api_token') !== null) {
    next()
    return
  }
  next('/')
}

const checkApproved = (to, from, next) => {
  if (store.state.user.profile.status === 'approved') {
    next()
    return
  }
  next('/profile')
}
/* eslint-disable-next-line */
const checkAuthorizedApproved = (to, from, next) => {
  checkAuthorized(to, from, next)
  checkApproved(to, from, next)
}
const checkAuthorizedAndClearModel = (to, from, next) => {
  checkAuthorized(to, from, next)
  store.commit('emptyModelOffers')
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('./components/pages/Login.vue'),
      beforeEnter: checkNotAuthorized,
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import('./components/pages/Registration.vue'),
      beforeEnter: checkNotAuthorizedProfile,
    },
    {
      path: '/profile',
      name: 'Account information',
      component: () => import('./components/pages/Profile.vue'),
      beforeEnter: checkAuthorized,
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('./components/pages/Welcome.vue'),
      beforeEnter: checkAuthorized,
    },
    {
      path: '/goods',
      beforeEnter: (to, from, next) => {
        next('/goods/1')
      },
    },
    {
      path: '/goods/:status',
      name: 'goods',
      component: () => import('./components/pages/Goods.vue'),
      beforeEnter: checkAuthorizedAndClearModel,
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('./components/pages/Success.vue'),
      beforeEnter: checkUnfinishedValidation,
    },
    {
      path: '/search-product',
      name: 'models.find',
      component: import('./components/pages/search-product/SearchProduct.vue'),
      beforeEnter: checkAuthorized,
    },
    {
      path: '/create',
      component: () => import('./components/pages/models/AddNewModelPage.vue'),
      beforeEnter: checkAuthorizedAndClearModel,
      children: [
        {
          path: 'model',
          name: 'models.creating',
          component: () => import('./components/pages/models/AddNewModel.vue'),
          beforeEnter: checkAuthorized,
        },
        {
          path: 'product/:modelId',
          name: 'models.creatingProduct',
          component: () => import('./components/pages/models/AddProductToModel.vue'),
          beforeEnter: checkAuthorized,
        },
      ],
    },
    {
      path: '/edit',
      name: 'Edit',
      component: () => import('./components/pages/EditPage.vue'),
      beforeEnter: checkAuthorized,
      children: [
        {
          path: 'model/:modelId',
          name: 'editModel',
          component: () => import('./components/pages/models/EditModel.vue'),
        },
      ],
    },
    {
      path: '/model/:modelId',
      name: 'model',
      component: () => import('./components/pages/models/ModelPage.vue'),
      beforeEnter: checkAuthorized,
    },
    {
      path: '/activation',
      name: 'activation',
      component: () => import('./components/pages/Activation.vue'),
      beforeEnter: checkNotAuthorized,
    },
    {
      path: '/orders',
      beforeEnter: (to, from, next) => {
        next('/orders/1')
      },
    },
    {
      path: '/orders/:status',
      name: 'orders',
      component: () => import('./components/pages/orders/Orders.vue'),
      beforeEnter: checkAuthorized,
      children: [
        {
          path: 'order/:orderId',
          name: 'Order',
          component: () => import('./components/pages/orders/Order.vue'),
          beforeEnter: checkAuthorized,
        },
      ],
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('./components/pages/billing/BillingPage.vue'),
      beforeEnter: checkAuthorized,
      children: [
        {
          path: ':id',
          name: 'Payment',
          component: () => import('./components/pages/billing/BillingPayment.vue'),
          beforeEnter: checkAuthorized,
        },
      ],
    },
    {
      path: '/help-center',
      name: 'helpCenter',
      component: () => import('./components/pages/help-center/HelpCenterPage.vue'),
    },
    {
      path: '/help-center-all',
      name: 'helpCenterAll',
      component: () => import('./components/pages/help-center/HelpCenterAll.vue'),
      children: [
        {
          path: 'section/:sectionId',
          name: 'helpCenterSection',
          component: () => import('./components/pages/help-center/HelpCenterSection.vue'),
        },
        {
          path: 'topic/:topicSlug',
          name: 'helpCenterTopic',
          component: () => import('./components/pages/help-center/HelpCenterTopic.vue'),
        },
      ],
    },
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
  },
})

export default router
