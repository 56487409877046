<template>
  <div
    class="error-panel"
    :class="serverErrorPanel.visible ? 'show' : 'hide'"
    v-if="serverErrorPanel.show">
    <img class="error-panel__graphics" src="@/assets/server-error.svg">
    <div class="error-panel__text">
      {{$t('pleaseBePatient')}} <a href="mailto:seller@smarket.hk">{{ $t('smEmail') }}</a> {{$t('tryAgainLater')}}
    </div>
    <div class="error-panel__error">
      500 - {{$t('eternalServerError')}}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServerErrorPanel',
  computed: {
    ...mapState({ serverErrorPanel: state => state.ui.serverErrorPanel }),
  },
  methods: {
    close () {
      this.$store.commit('closeAnimateServerErrorPanel')
      setTimeout(() => { this.$store.commit('closeServerErrorPanel') }, 400)
    },
  },
  watch: {
    serverErrorPanel: {
      handler (serverErrorPanel) {
        if (serverErrorPanel.visible) {
          setTimeout(() => { this.close() }, serverErrorPanel.visibilityTime)
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.error-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 0 0 0;
  background: #FFFEF8;
  border-bottom: solid 1px #EEBC51;
  z-index: 999;

  &__graphics {
    display: block;
    margin: 0 auto 16px auto;
  }

  &__text {
    margin: 0 0 8px 0;
    color: #333333;
    font-size: 16px;
    text-align: center;

    a {
      color: #333333;
      text-decoration: none;
      font-weight: 700;
    }
  }

  &__error {
    margin: 0 0 16px 0;
    color: #EEBC51;
    font-size: 14px;
    text-align: center;
  }
}

.show {
  opacity: 0.95;
  transition: opacity 400ms;
}
.hide {
  opacity: 0;
  transition: opacity 400ms;
}
</style>
