import { CHN } from '@/helpers/consts/bank-country-codes'

export const defaultBankCountryCode = {
  methods: {
    setDefaultBankCountyCode () {
      this.$store.commit(
        'setProfileValues',
        {
          handle: 'bankCountryCode',
          value: CHN,
        }
      )
    },
  },
}
