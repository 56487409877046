import api from '@/api/api'

const state = {
  sections: null,
  topics: null,
  topic: null,
  section: null,
  questions: null,
  userQuestion: {
    companyName: '',
    email: localStorage.getItem('email') || '',
    message: '',
  },
}

const getters = {}

const actions = {
  async getHelpSections ({ commit }, locale) {
    try {
      commit('setLoadingStatus')
      const response = await api.getHelpSectionsList(locale)
      commit('setSections', response.data)
    } finally {
      commit('setSuccessStatus')
    }
  },
  async getHelpTopic ({ commit }, slug) {
    try {
      commit('setLoadingStatus')
      const response = await api.getHelpTopicBySlug(slug)
      commit('setTopic', response.data)
      return response.data
    } finally {
      commit('setSuccessStatus')
    }
  },
  async getHelpTopics ({ commit }, term) {
    try {
      commit('setLoadingStatus')
      const response = await api.getHelpTopics(term)
      commit('setTopics', response.data)
      return response.data
    } finally {
      commit('setSuccessStatus')
    }
  },
  async likeTopic ({ commit, state }) {
    await api.likeHelpTopic(state.topic.id)
    commit('changeTopicLikeCount', state.topic.likeCount + 1)
  },
  async dislikeTopic ({ commit, state }) {
    await api.dislikeHelpTopic(state.topic.id)
    commit('changeTopicLikeCount', state.topic.likeCount - 1)
  },
  selectSectionById ({ commit, state }, sectionId) {
    const section = state.sections ? state.sections.filter(section => section.id === sectionId)[0] : null
    commit('selectSection', section)
  },
  selectTopicBySlug ({ commit, state }, slug) {
    const topic = state.section ? state.section.topics.filter(topic => topic.slug === slug)[0] : null
    commit('selectTopic', topic)
  },
  async sendFeedback ({ state }) {
    return api.sendFeedback(state.userQuestion)
  },
  async askQuestion ({ commit }, data) {
    return api.askHelpQuestion(data)
  },
}

const mutations = {
  setSections (state, sections) {
    state.sections = sections
  },
  setTopic (state, topic) {
    state.topic = topic
  },
  setTopics (state, topics) {
    state.topics = topics
  },
  changeTopicLikeCount (state, count) {
    state.topic.likeCount = count
  },
  updateQuestion (state, payload) {
    state.userQuestion[payload.name] = payload.value
  },
  selectSection (state, section) {
    state.section = section
  },
  selectTopic (state, topic) {
    state.topic = topic
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
