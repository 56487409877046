import api from '@/api/api'
import { BATCH_CREATE, BATCH_UPDATE } from '../../helpers/consts/batch-types'

const state = {
  products: [],
  productsPagination: {},
  modelExists: false,
  isModelEdit: false,
  modelId: '',
  productId: '',
  attributeKeys: [],
  attributes: [],
  optionsObj: {},
  imgPreview: [],
  brand: {
    items: [],
    pagination: {},
  },
  categories: [],
  categoriesList: {
    items: [],
    pagination: {},
  },
  types: {
    items: [],
    pagination: {},
  },
  searchFields: {},
  model: {
    items: [],
    pagination: {},
  },
  newModel: {
    brandName: '',
    categoryName: '',
    typeName: '',
    model: {
      brandId: null,
      categoryId: null,
      typeId: null,
      name: '',
      nameCn: '',
      description: '',
      status: 'active',
    },
  },
  dimensions: {
    weight: null,
    length: null,
    height: null,
    width: null,
  },
  myModel: {
    products: [],
  },
  branCategoryFilled: false,
  offers: [],
  productOffers: [],
  hsCode: '',
  productsAvailable: [],
}

const getters = {
  productOffers: state => state.productOffers,
  brandCategoryIsFilled: state => state.branCategoryFilled,
  modelExists: state => state.modelExists,
  newModel: state => state.newModel,
  isModelEdit: state => state.isModelEdit,
  dimensions: state => state.dimensions,
  modelId: state => state.modelId,
  hsCode: state => state.hsCode,
}

const actions = {
  async getBrand ({ commit, dispatch }, name) {
    try {
      commit('setLoadingStatus')
      commit('setAuthorizationHeader')
      const response = await api.getBrand(name)
      commit('setBrand', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
      throw error
    }
  },

  async addBrand ({ commit, dispatch }, brandName) {
    try {
      commit('setLoadingStatus')
      commit('setAuthorizationHeader')
      const response = await api.addBrand(brandName)
      commit('setSuccessStatus')
      return response
    } catch (error) {
      dispatch('setAllErrors', error)
      throw error
    }
  },

  async addType ({ commit, dispatch }, typeName) {
    try {
      commit('setLoadingStatus')
      commit('setAuthorizationHeader')
      const response = await api.addType(typeName)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
      throw error
    }
  },

  async getCategory ({ commit, dispatch }, name) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getCategory(name)
      commit('setCategory', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
      throw error
    }
  },

  async getAllCategories ({ commit, dispatch }) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getAllCategories()
      commit('setAllCategories', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
      throw error
    }
  },

  async getCategoriesList ({ commit, dispatch }, query) {
    try {
      const response = await api.getCategoriesList({ q: query })
      commit('setCategoriesList', response.data)
      return response.data
    } catch (error) {
      throw error
    }
  },

  async getType ({ commit, dispatch }, payload) {
    try {
      payload = (typeof payload === 'string') ? { name: payload } : payload
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getType(payload)
      commit('setType', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  pushType ({ commit }, type) {
    commit('pushType', type)
  },

  pushBrand ({ commit }, brand) {
    commit('pushBrand', brand)
  },

  async getModel ({ commit, dispatch }, payload) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getModel(payload)
      commit('setModel', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async getModelById ({ commit, dispatch }, id) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      commit('removeAllErrors')
      const response = await api.getModelById(id)
      commit('setMyModel', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      throw error
    }
  },

  async getAllModels ({ commit, dispatch }, payload) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getAllModels(payload)
      commit('setModel', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async addModel ({ commit, dispatch }) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      commit('removeAllErrors')
      const response = await api.addModel(state.newModel.model)
      commit('setModelId', response.data.id)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async useModel ({ commit }, model) {
    await commit('setFoundModel', model)
  },

  async modifyModel ({ commit, dispatch, state }) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      commit('removeAllErrors')
      const payload = {
        modelId: state.modelId,
        data: state.newModel.model,
      }
      const response = await api.modifyModel(payload)
      commit('emptyModelOffers')
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async addProductToModel ({ commit, dispatch, state }, key) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      commit('removeAllErrors')
      const payload = {
        modelId: state.modelId,
        offer: state.productOffers[key],
      }
      const response = await api.addProductToModel(payload)
      commit('setSuccessStatus')
      return response
    } catch (error) {
      dispatch('setAllErrors', error, key)
      throw error
    }
  },

  async modifyProduct ({ commit, dispatch }, { data, id }) {
    try {
      commit('setAuthorizationHeader')
      commit('removeAllErrors')
      const response = await api.modifyProduct({ data, id })
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async getProducts ({ commit, dispatch }, page) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getProducts(page)
      commit('setProducts', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async getProductsAvailable ({ commit, dispatch }, modelId) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getProductsAvailable(modelId)
      commit('setProductsAvailable', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      // Do nothing, because product list realy can be empty with 404 error code
      // Please, don't fire error
    }
  },

  async getAttrValue ({ commit }) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getAttrValue()
      commit('setAttrValue', response.data)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      commit('setErrorStatus', error)
      throw error
    }
  },

  async getAttr ({ commit, dispatch }, keyId) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getAttr(keyId)
      const data = response.data
      commit('setAttr', { data, keyId })
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async getAttrKey ({ commit, dispatch }, params) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      commit('removeAllErrors')
      const response = await api.getAttrKey(params)
      commit('setModelAttributes', response.data.items)
      commit('setSuccessStatus')
      return response.data.items
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async loadImages ({ commit, dispatch }, imageFormData) {
    try {
      const response = await api.loadImages(imageFormData)
      let imageUrl = response.data.path
      imageUrl = imageUrl.replace('http://localhost/', 'http://api.stage01.umko/')
      return imageUrl
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  // TODO: use this method for creating new variation
  async addProductsToModel ({ commit, dispatch }, modelId) {
    try {
      commit('setLoadingStatus')
      commit('setAuthorizationHeader')
      const payload = {
        modelId,
        data: { product: {} },
      }
      const response = await api.addProductsToModel(payload)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  setModelType ({ commit, dispatch }, typeObject) {
    commit('setTypeToNewModel', typeObject)
  },
  setModelBrand ({ commit, dispatch }, brandObject) {
    commit('setBrandToNewModel', brandObject)
  },
  setModelCategory ({ commit }, categoryObject) {
    commit('setCategoryToNewModel', categoryObject)
  },
  setDimension ({ commit, dispatch }, payload) {
    commit('setDimensions', payload)
  },
  setHsCode ({ commit, dispatch }, payload) {
    commit('setHsCode', payload)
  },
  setExistenceModel ({ commit, dispatch }, model) {
    commit('setExistenceModel', model)
  },

  async uploadBatchFile ({ commit, dispatch }, { file, type }) {
    commit('setLoadingStatus')
    try {
      let response = null
      switch (type) {
        case BATCH_CREATE:
          response = await api.batchCreate({ file })
          break
        case BATCH_UPDATE:
          response = await api.batchUpdate({ file })
          break
      }
      return response
    } catch (error) {
      dispatch('showError', error)
    } finally {
      commit('setSuccessStatus')
    }
  },
}

const mutations = {
  setAllCategories (state, data) {
    const topCategoryArray = []
    const otherCategoryArray = []
    data.items.forEach(category => {
      if (!category.isFinalCategory) {
        topCategoryArray.push(category)
      } else {
        otherCategoryArray.push({ ...category, parentId: 9999 })
      }
    })
    state.categories = [...topCategoryArray, {
      title: 'Other',
      id: 9999,
      children: otherCategoryArray,
    }]
  },
  setCategoriesList (state, categoriesList) {
    state.categoriesList = categoriesList
  },
  emptySelectedAttrs (state) {
    state.selectedAttrs = []
  },
  setBrandCategoryToNewModel (state, payload) {
    state.newModel.model.brandId = payload.brandId
    state.newModel.model.categoryId = payload.categoryId
    state.newModel.model.typeId = payload.typeId
    state.newModel.brandName = payload.brandName
    state.newModel.typeName = payload.typeName
    state.newModel.categoryName = payload.categoryName
  },
  setTypeToNewModel (state, payload) {
    if (payload) {
      state.newModel.model.typeId = payload.id
      state.newModel.typeName = payload.label
    } else {
      state.newModel.model.typeId = null
      state.newModel.typeName = null
    }
  },
  setBrandToNewModel (state, payload) {
    if (payload) {
      state.newModel.model.brandId = payload.id
      state.newModel.brandName = payload.label
    } else {
      state.newModel.model.brandId = null
      state.newModel.brandName = null
    }
  },
  setCategoryToNewModel (state, payload) {
    if (payload) {
      state.newModel.model.categoryId = payload.id
      state.newModel.categoryName = payload.title
    } else {
      state.newModel.model.categoryId = null
      state.newModel.categoryName = null
    }
  },
  setBrandCategoryStatus (state, status) {
    state.branCategoryFilled = status
  },
  setProductValues (state, payload) {
    const key = payload.key
    const name = payload.name
    state.productOffers[key][name] = payload.value
  },
  pushType (state, type) {
    state.types.items.push(type)
  },
  pushBrand (state, brand) {
    state.brand.items.push(brand)
  },
  setInitialValues (state) {
    const width = state.newModel.width
    const height = state.newModel.height
    const length = state.newModel.length
    const weight = state.newModel.weight
    // const { height, length, weight, width } = state.newModel
    const description = state.newModel.model.description
    state.offers.forEach((offer, i) => {
      state.productOffers[i] = {
        name: '',
        hsCode: '',
        weight: weight,
        width: width,
        length: length,
        height: height,
        attributes: [],
        images: [],
        stock: '',
        price: '',
        discountPrice: '',
        description: description,
        status: 'active',
      }
      Object.keys(offer).forEach((keyId) => {
        const valueId = offer[keyId].id
        state.productOffers[i].attributes.push({
          'key': +keyId,
          'value': +valueId,
        })
      })
    })
  },
  setNewModelValues (state, payload) {
    state.newModel.model[payload.handle] = payload.value
  },
  setPreProductValues (state, payload) {
    state.newModel[payload.handle] = payload.value
  },
  setBrand (state, data) {
    state.brand = data
  },
  setCategory (state, data) {
    state.categories = data
  },
  setType (state, data) {
    state.types = data
  },
  setModel (state, data) {
    state.model = data
  },
  setModelId (state, id) {
    state.modelId = id
  },
  setModelSearchFields (state, payload) {
    state.searchFields = {
      brandId: payload.brandId,
      categoryId: payload.categoryId,
      typeId: payload.typeId,
    }
  },
  setFoundModel (state, foundModel) {
    let model = foundModel
    state.modelId = model.id
    delete model.id
    state.newModel.model = model
    state.modelExists = true
  },
  setExistenceModel (state, model) {
    state.modelId = model.id
    state.newModel.brandName = model?.brand?.name
    state.newModel.categoryName = model?.category?.name
    state.newModel.typeName = model?.type?.name
    state.newModel.model.brandId = model?.brand?.id
    state.newModel.model.categoryId = model?.category?.id
    state.newModel.model.typeId = model?.type?.id
    state.newModel.model.status = model.status
    state.newModel.model.name = model.name
    state.newModel.model.spu = model.spu
    state.newModel.model.description = model.description
    if (model.nameCn) {
      state.newModel.model.nameCn = model.nameCn
    }
    state.modelExists = false
    state.isModelEdit = true
  },
  setProducts (state, data) {
    state.products = data.items
    state.productsPagination = data.pagination
  },
  setProductsAvailable (state, data) {
    state.productsAvailable = data || []
  },
  setAttrKey (state, data) {
    let attrArray = []
    attrArray = [...state.attributeKeys, ...data.items]
    state.attributeKeys = attrArray
  },
  setAttrValue (state, data) {
    state.attributeValue = data
  },
  setAttr (state, payload) {
    const keyId = payload.keyId
    let optionsArr = []
    state.attributes = [...state.attributes, ...payload.data.items]
    state.attributes.forEach(attr => {
      if (attr.key.id === keyId) {
        optionsArr.push({ id: attr.value.id, label: attr.value.name })
      }
    })
    let optObj = {}
    optObj[keyId] = optionsArr
    state.optionsObj = { ...state.optionsObj, ...optObj }
  },
  emptyModelOffers (state) {
    state.modelExists = false
    state.isModelEdit = false
    state.branCategoryFilled = false
    state.newModel = {
      brandName: '',
      categoryName: '',
      typeName: '',
      model: {
        brandId: '',
        categoryId: null,
        typeId: null,
        spu: '',
        name: '',
        nameCn: '',
        description: '',
        status: 'active',
      },
    }
    state.modelId = ''
    state.productId = ''
    state.attributeKeys = []
    state.attributes = []
    state.optionsObj = {}
    state.imgPreview = []
    state.brand = {
      items: [],
      pagination: {},
    }
    state.categories = []
    state.searchFields = {}
    state.model = {
      items: [],
      pagination: {},
    }
    state.types = {
      items: [],
      pagination: {},
    }
    state.offers = []
    state.productOffers = []
  },
  setAttrs (state, attrsObj) {
    let offerObj = {}
    offerObj = { ...attrsObj }
    let arr = []
    arr.push(offerObj)
    state.offers = [...state.offers, ...arr]
  },
  setImagesToProduct (state, { offerIndex, imageUrls }) {
    let offerCopy = state.productOffers[offerIndex]
    offerCopy.images = [...imageUrls]
    state.productOffers[offerIndex] = offerCopy
  },
  setDimensions (state, payload) {
    payload.map(dimension => {
      state.dimensions[dimension.name] = dimension.value
    })
  },
  setHsCode (state, hsCode) {
    state.hsCode = hsCode
  },
  setMyModel (state, model) {
    state.myModel = model
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
