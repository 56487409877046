import { createStore } from 'vuex'
import showcase from './modules/showcase'
import ui from './modules/ui'
import user from './modules/user'
import products from './modules/products'
import orders from './modules/orders'
import billing from '@/store/modules/billing'
import models from './modules/models'
import model from '@/store/modules/model'
import helpCenter from '@/store/modules/help-center'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    showcase,
    ui,
    user,
    products,
    orders,
    billing,
    models,
    model,
    helpCenter,
  },
  strict: debug,
})

export default store
