<template>
  <div class="header-notification">
    <div
      class="header-notification--content"
      :class="{'header-notification--content-center': headerMessage.alignCenter}"
    >
      <svg-icon
        class-name="header-notification--icon"
        :iconName="headerMessage.icon"
      />
      <div
        class="header-notification--text"
        v-html="headerMessage.text"
      ></div>
      <SecondaryButton
        v-if="headerMessage.hasButton"
        :value="headerMessage.buttonText"
        @click="() => { $store.dispatch(headerMessage.buttonAction)}"
      />
    </div>
    <svg-icon
      className="header-notification--close"
      iconName="close-light"
      @click="close"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SecondaryButton from './buttons/SecondaryButton'

export default {
  name: 'HeaderNotification',
  components: {
    SecondaryButton,
  },
  computed: {
    ...mapState({
      headerMessage: state => state.ui.headerMessage,
    }),
  },
  methods: {
    close () {
      this.$store.dispatch('hideHeaderNotification')
    },
  },
}
</script>

<style scoped lang="scss">
.header-notification {
  background-color: $color-message-bg;
  height: 52px;
  position: relative;

  &--content {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--icon {
    width: 26px;
    height: 17px;
    fill: transparent;
    stroke: $text-black;
    margin-right: 8px;
  }

  &--text {
    flex: 1 0 auto;
  }

  &--content-center &--text {
    flex: 0 0 auto;
    margin-right: 8px;
  }

  &--close {
    width: 12px;
    height: 12px;
    stroke: $color-secondary-gray;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
</style>
