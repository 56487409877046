import axios from 'axios'
import store from '../store/store'
import router from '../router'
import BasicApi from 'shared/src/api/api'

class Api extends BasicApi {
  async _request ({ url, method, headers, params, data, responseType }) {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('api_token')}`
      headers = this._setContentType(headers)
      data = this._prepareData(headers, data)
      return await axios({ url, method, headers, params, data, responseType })
    } catch (error) {
      if (error?.response?.status === 401) {
        await store.dispatch('logout')
        await store.commit('setSuccessStatus')
        if (router.currentRoute.fullPath !== '/') router.push('/')
      }
      throw error
    }
  }

  async activate (token) {
    try {
      return await axios({
        url: `activate/${token}`,
        method: 'GET',
      })
    } catch (error) {
      throw error
    }
  }

  async getProfile () {
    return this._basicGet('profile')
  }

  async updateProfile (payload) {
    return this._basicPost('profile', payload)
  }

  async updateBankCredentials (payload) {
    return this._basicPost('/profile/bank-credentials', payload)
  }

  async verifyProfile () {
    return this._basicPost('/profile/approve')
  }

  async getModels (payload) {
    payload.mode = 'detailed'
    return this._basicGet('model', payload)
  }

  async getProductsAvailable (id) {
    try {
      return this._basicGet(`model/${id}/products-availabe`)
    } catch (error) {
      return []
    }
  }

  async getModelStatusesWithCount () {
    return this._basicGet('model/status-model-count')
  }

  async updateProduct (payload) {
    return this._basicPut(`product/${payload.id}`, payload.stock)
  }

  async getOrders (payload) {
    return this._basicGet('order', payload)
  }

  async getOrder (payload) {
    return this._basicGet(`order/${payload.orderId}`)
  }

  async getStatuses () {
    return this._basicGet('order/status-order-count')
  }

  async changeOrderStatus (orderId, status) {
    try {
      return await this._request({
        url: `order/${orderId}`,
        method: 'PUT',
        params: { orderId },
        data: { status },
      })
    } catch (error) {
      throw error
    }
  }

  async loadShippingLabel (payload) {
    try {
      return await this._request({
        url: `order/${payload}/label`,
        method: 'GET',
        headers: { 'Content-Type': 'application/pdf' },
        responseType: 'blob',
      })
    } catch (error) {
      throw error
    }
  }

  async getBrand (name) {
    return this._basicGet('brand', {
      name,
      perPage: 100,
    })
  }

  async addBrand (name) {
    return this._basicPost('brand', { name })
  }

  async addType (name) {
    return this._basicPost('type', { name })
  }

  async getCategory (name) {
    return this._basicGet('category', {
      name,
      perPage: 100,
    })
  }

  async getAllCategories () {
    return this._basicGet('/category/all')
  }

  async getCategoriesList (payload) {
    return this._basicGet('/category/list', payload)
  }

  async getType (payload) {
    return this._basicGet('type', {
      name: payload.name,
      categoryId: payload.categoryId,
      perPage: 100,
    })
  }

  async getModel (payload) {
    return this._basicGet('model', {
      brandId: payload.brandId,
      categoryId: payload.categoryId,
      typeId: payload.typeId,
      term: payload.term,
      page: payload.page,
      mode: payload.list || 'list',
    })
  }

  async getModelById (id) {
    return this._basicGet(`model/${id}`)
  }

  async getAllModels (payload) {
    return this._basicGet('model/search', {
      brandId: payload.brandId,
      categoryId: payload.categoryId,
      typeId: payload.typeId,
      term: payload.term,
      page: payload.page,
      mode: payload.list || 'list',
    })
  }

  async addModel (payload) {
    return this._basicPost('model', payload)
  }

  async modifyModel (payload) {
    return this._basicPut(`model/${payload.modelId}`, payload.data)
  }

  async addProductToModel (payload) {
    return this._basicPost(`product/${payload.modelId}`, payload.offer)
  }

  async modifyProduct (payload) {
    return this._basicPut(`product/${payload.id}`, payload.data)
  }

  async getProducts (page) {
    return this._basicGet('product', {
      own: true,
      perPage: 20,
      page,
    })
  }

  async getAttrValue () {
    return this._basicGet('attribute/value')
  }

  async getAttr (keyId) {
    return this._basicGet('attribute', { keyId })
  }

  async getAttrKey (payload) {
    return this._basicGet('attribute/key', payload)
  }

  async loadImages (payload) {
    try {
      return await this._request({
        url: 'image',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: payload,
      })
    } catch (error) {
      throw error
    }
  }

  async addProductsToModel (payload) {
    return this._basicPost(`product/${payload.modelId}`, payload.data)
  }

  async getPayments (params = null) {
    return this._basicGet('payment', params)
  }

  async getPayment (id) {
    return this._basicGet(`payment/${id}`)
  }

  async getAttrValues (params) {
    return this._basicGet('/attribute/value', params)
  }

  async addProduct (modelId, product) {
    return this._basicPost(`/product/${modelId}`, product)
  }

  async makeOffer (productId, variation) {
    return this._basicPost(`/product/${productId}/make-offer`, variation)
  }

  async seenWelcomePage () {
    return this._basicPost('profile/seen-welcome-page')
  }

  async resendConfirmEmail (email) {
    try {
      const response = await this._basicPost('/user/resend-confirm-email', { email })
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getFile (path) {
    try {
      const response = await this._request({
        url: `${path}`,
        method: 'GET',
        responseType: 'blob',
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getBusinessTypes () {
    try {
      const response = await this._basicGet('/profile/business-type')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getHelpSectionsList (locale) {
    return this._basicGet('/help/section', { locale })
  }

  async getHelpTopicBySlug (slug) {
    return this._basicGet(`/help/topic/${slug}`)
  }

  async getHelpTopics (term) {
    return this._basicGet(`/help/topic`, { term })
  }

  async likeHelpTopic (id) {
    return this._basicPost(`/help/topic/${id}/like`)
  }

  async dislikeHelpTopic (id) {
    try {
      const response = await this._request({
        url: `/help/topic/${id}/like`,
        method: 'DELETE',
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  async askHelpQuestion (data) {
    return this._basicPost('/help/question', data)
  }

  async getHelpQuestion (topicId, onlyAnswered) {
    return this._basicGet(`/help/topic`, { topicId, onlyAnswered })
  }

  async sendFeedback (data) {
    return this._basicPost('/feedback', data)
  }

  async getLanguage (language) {
    return this._basicGet(`/translate`, { language })
  }

  async batchCreate (data) {
    try {
      return await this._request({
        url: '/product/create-by-xlsx',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      })
    } catch (error) {
      throw error
    }
  }

  async batchUpdate (data) {
    try {
      return await this._request({
        url: '/product/update-stock-xlsx',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      })
    } catch (error) {
      throw error
    }
  }
}

export default new Api()
