<!--suppress CssUnknownTarget -->
<template>
  <div
    v-if="$i18n.locale"
    class="languages"
    :class="isOpen ? 'languages_active' : ''"
    v-click-outside="closeMenu"
    @click="toggleMenu"
    >
    <div class="languages__spoiler">
      <img :src="require(`@/assets/flags/${$i18n.locale}.png`)"/>
      {{ $i18n.locale }}
    </div>
    <ul class="languages__list">
      <li
        class="languages__item"
        v-for="(lang, i) in langs"
        :key="`lang.id${i}`"
        :class="lang.id === $i18n.locale ? 'languages__item_active' : ''"
        @click="setLocale(lang.id)"
        >
        <img :src="require(`@/assets/flags/${lang.id}.png`)"/>
        {{ lang.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { loadLanguageAsync } from '@/helpers/load-languages-async'

export default {
  name: 'TheLanguageSelector',
  data () {
    return {
      langs: [
        { id: 'en', title: 'English' },
        { id: 'cn', title: 'Chinese' },
      ],
      isOpen: false,
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    },
    closeMenu () {
      this.isOpen = false
    },
    async setLocale (locale) {
      this.$i18n.locale = locale
      await this.$store.commit('setLocale', locale)
      try {
        await loadLanguageAsync(locale)
      } catch (error) {
        await this.$store.dispatch('showError', error)
      }
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
}
</script>

<style lang="scss" scoped>
.languages {
  position: relative;
  background-color: $color-grey;
  transition: 0.3s border-color;
  border-radius: 20px;
  box-sizing: border-box;
  line-height: 18px;
  padding: 10px;
  cursor: pointer;
  margin-right: 24px;
  &:hover {
    border-color: $color-header_hover;
  }
}

.languages__spoiler {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  gap: 6px;
  justify-content: center;
}

.languages__list {
  position: absolute;
  top: 40px;
  left: 0;
  background: $color-white;
  display: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.languages__item {
  display: flex;
  gap: 6px;
  justify-content: center;
  position: relative;
  padding: 10px 70px 10px 17px;
  transition: 0.3s ease;
  text-align: left;
  cursor: pointer;
  line-height: 20px;

  &:hover {
    background: $color-hover;
  }
}
.languages__item_active {
  color: $color-primary;

  &:before {
    position: absolute;
    content: "";
    background: url("~@/assets/arrow_check.png");
    width: 16px;
    height: 11px;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: 0.3s ease;
  }
}

.languages_active {
  .languages__list {
    display: block;
  }

  &:before {
    transform: rotate(180deg);
  }
}
</style>
