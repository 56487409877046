<!--suppress CssUnusedSymbol -->
<template>
  <v-select id="base-select"
            class="base-select"
            :options="options"
            :value="value"
            :clearable="clearable"
            :placeholder="placeholder"
            :searchable="searchable"
            :disabled="disabled"
            :filterable="filterable"
            :label="label"
            @search="$emit('search', $event)"
            @input="onInput"
            @open="$emit('open', $event)"
            :calculatePosition="withPopper"
  >

    <template #open-indicator="{ attributes }">
      <SvgIcon v-bind="attributes" icon-name="arrow-mini"/>
    </template>

    <template #list-header>
      <slot name="header"></slot>
    </template>

    <template #list-footer>
      <slot name="footer"></slot>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import SvgIcon from 'shared/src/components/SvgIcon'
import { createPopper } from '@popperjs/core'

export default {
  name: 'base-select',
  props: [
    'options',
    'placeholder',
    'value',
    'clearable',
    'searchable',
    'disabled',
    'filterable',
    'label',
  ],
  components: {
    SvgIcon,
    vSelect,
  },
  data () {
    return {
      placement: 'bottom',
    }
  },

  methods: {
    onInput (selected) {
      this.$emit('input', selected)
    },
    withPopper (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.classList.add('pagination-dropdown')
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle('drop-down', state.placement === 'bot')
            },
          }],
      })
      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/vue-select/dist/vue-select.css";
@import "../../styles/style";

  .base-select {
    min-width: 93px;
    background-color: #fff;
  }

  #app .vs--open .vs__dropdown-toggle {
    border-color: $color-primary;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  #app .vs--single.vs--open .vs__selected {
    opacity: 1;
    position: relative;
  }

  .base-select .vs__open-indicator {
    width: 17px;
    height: 7px;
    fill: transparent;
    color: transparent;
    background-color: transparent;
    border: none;
    cursor: pointer;
    stroke: rgba(111, 117, 124, 0.5);
  }

  .base-select .vs__dropdown-menu {
    min-width: auto;
    top: calc(100% + 7px);
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .base-select ::v-deep(.vs__dropdown-option) {
    padding-right: 31px;
    box-sizing: border-box;
    color: $text-black;

    &--selected {
      position: relative;
      background-color: $color-hover;
      &:before {
        position: absolute;
        content: "";
        background: url("~@/assets/arrow_check.png");
        width: 16px;
        height: 11px;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: 0.3s ease;
      }
    }

    &--highlight {
      background-color: $color-hover;
    }
  }
</style>

<style>
.base-select .vs__clear {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: transparent;
  stroke: transparent;
  background-image:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTggOGw0IDRtNCA0bC00LTRtMCAwbDQtNG0tNCA0bC00IDQiIHN0cm9rZT0iI0I3QkFCRCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
