import helpers from '@/helpers/helpers'

const state = {
  headerMessage: {
    visible: false,
    icon: '',
    text: '',
    alignCenter: false,
    hasButton: false,
    buttonText: '',
    buttonAction: '',
  },
  hintNotifications: [],
  errors: {},
  serverErrorPanel: {
    visible: false,
    show: false,
    visibilityTime: 5000,
  },
  status: '',
  overlay: false,
  appStyles: {},
  overlayType: '',
  scrollEnable: true,
}

const getters = {
  isHeaderMessageVisible: state => state.headerMessage.visible,
}

const actions = {
  showError ({ commit, dispatch }, error) {
    commit('setErrorStatus', error)
    if (error && error.response && error.response.status &&
        error.response.status >= 500 && error.response.status < 600) {
      commit('showServerErrorPanel')
    } else {
      const title = error
      if (helpers.errorResponseExists(error)) {
        const data = error.response.data
        if (data instanceof Array) {
          data.forEach(item => {
            commit('showHintNotification', {
              type: 'ERROR',
              title: item.property_path,
              description: item.message,
            })
          })
        } else {
          commit('showHintNotification', {
            type: 'ERROR',
            title,
            description: data.message,
          })
        }
      } else {
        commit('showHintNotification', {
          type: 'ERROR',
          title,
        })
      }
    }
  },
  setAllErrors ({ commit, dispatch }, error, key) {
    commit('setErrorStatus', error)
    if (error && error.response && error.response.status &&
      error.response.status >= 500 && error.response.status < 600) {
      commit('showServerErrorPanel')
    } else {
      const title = error
      const errorResponse = error.response
      if (errorResponse) {
        if (errorResponse.data[0] && errorResponse.data[0].property_path) {
          key = key || 0
          errorResponse.data.forEach(err => {
            const fieldName = err.property_path
            const text = err.message
            commit('setErrorToField', { fieldName, text, key })
          })
        } else {
          commit('showHintNotification', {
            type: 'ERROR',
            title,
            description: errorResponse.data.message,
          })
        }
      } else {
        commit('showHintNotification', {
          type: 'ERROR',
          title,
          description: errorResponse.data.message,
        })
      }
    }
  },
  setHeaderMessage ({ commit, dispatch }, message) {
    message.visible = true
    commit('setHeaderMessage', message)
  },
  hideHeaderNotification ({ commit }) {
    commit('clearHeaderMessage')
  },
  showScrollableOverlay ({ commit }) {
    commit('setAppStyles', {
      position: 'relative',
    })
    commit('setOverlayType', 'invisible')
    commit('showOverlay')
  },
  hideScrollableOverlay ({ commit }) {
    commit('setAppStyles', {})
    commit('setOverlayType', '')
    commit('hideOverlay')
  },
}

const mutations = {
  setOverlayType (state, type) {
    state.overlayType = type
  },
  setAppStyles (state, styles) {
    state.appStyles = styles
  },
  showHintNotification (state, { type, title, description, timeout }) {
    state.hintNotifications.push({
      type,
      title,
      description,
      timeout,
      id: Math.random() * Math.random(),
    })
  },
  removeHintNotificationById (state, id) {
    state.hintNotifications = state.hintNotifications.filter(notification => notification.id !== id)
  },
  showServerErrorPanel (state) {
    state.serverErrorPanel.visible = true
    state.serverErrorPanel.show = true
  },
  closeNotification (state) {
    state.notification.showNotify = false
  },
  closeServerErrorPanel (state) {
    state.serverErrorPanel.show = false
  },
  closeAnimateNotification (state) {
    state.notification.visible = false
  },
  closeAnimateServerErrorPanel (state) {
    state.serverErrorPanel.visible = false
  },
  setLoadingStatus (state) {
    state.status = 'loading'
  },
  setSuccessStatus (state) {
    state.status = 'success'
  },
  setErrorStatus (state, error) {
    state.status = `error: ${error}`
  },
  resetError (state, payload) {
    if (!helpers.isEmptyObject(state.errors)) {
      const key = payload.key || 0
      const name = payload.name
      let errorsObj = { ...state.errors }
      errorsObj[key][name] = ''
      state.errors = errorsObj
    }
  },
  removeAllErrors (state) {
    state.errors = {}
  },
  setErrorToField (state, payload) {
    const name = payload.fieldName
    const text = payload.text
    state.errors[payload.key] = { name, text }
  },
  showOverlay (state) {
    state.overlay = true
  },
  hideOverlay (state) {
    state.overlay = false
    state.overlayType = ''
    state.appStyles = {}
  },
  setOverlayStatus (state, status) {
    state.overlay = status
  },
  setHeaderMessage (state, message) {
    state.headerMessage = message
  },
  clearHeaderMessage (state) {
    state.headerMessage = {
      visible: false,
    }
  },
  setScrollEnable (state, status) {
    state.scrollEnable = status
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
