
const state = {
  newModel: {

  },
  model: {

  },
  products: [],
  attributes: [],
}

const actions = {

}

const mutations = {
  setModelAttributes (store, attributes) {
    store.attributes = attributes
  },
}

export default {
  state,
  actions,
  mutations,
}
