<template>
  <div
    :class="fullWidth ? '__full-width' : ''"
    class="base-input-wrapper">
    <div
      class="base-input-container"
      :class="containerClassObj"
    >
      <div class="base-input-sign" v-if="sign">{{sign}}</div>
      <svg-icon
        v-if="hasIcon"
        :icon-name="iconName"
        class-name="base-input-container--icon"
      />
      <div class="base-input-input">
        <input
          @focusin="onFocusIn"
          @focusout="onFocusOut"
          @input="$emit('input', $event.target.value)"
          @change="$emit('change', $event.target.value)"
          @blur="onBlur"
          class="base-input"
          :value="value"
          :placeholder="placeholder"
          :type="type"
          :readonly="disabled"
          :name="name"
        />
        <span
          v-if="fullWidth === false"
          v-text="value"
          class="content-fit-emulation"
          aria-hidden="true"
        ></span>
      </div>
    </div>
    <div v-if="disabled === false" class="base-input-errors">
      <div class="base-input-error"
           v-for="(error, index) in errors"
           :key="index"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@shared/components/SvgIcon'
import debounce from 'lodash.debounce'
export default {
  components: { SvgIcon },
  data () {
    return {
      inputVal: '',
      isFocused: false,
    }
  },

  name: 'base-input',
  props: {
    type: {
      validator (value) {
        return ['text', 'textarea', 'password', 'url', 'email', 'date', 'number', 'tel'].includes(value)
      },
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    sign: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    focusOutEvent: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 1000,
    },
    errors: {
      type: Array,
    },
    name: {
      type: String,
      default: '',
    },
  },

  computed: {
    containerClassObj () {
      const obj = {
        '__focused': this.isFocused,
        '__readonly': this.disabled,
        '__full-width': this.fullWidth,
        '__error': this.invalid,
      }

      if (this.containerClass.length) {
        obj[this.containerClass] = true
      }

      return obj
    },

    hasIcon () {
      return this.iconName.length > 0
    },
  },

  methods: {
    onFocusIn () {
      this.isFocused = true
    },

    onFocusOut () {
      this.isFocused = false

      if (this.focusOutEvent) {
        this.$emit(this.focusOutEvent)
      }
    },

    onInput: debounce(function (event) {
      this.$emit('input', event.target.value)
    }, 100),

    onBlur: debounce(function (event) {
      this.$emit('blur', event.target.value)
    }, 100),
  },

  mounted () {
    this.inputVal = this.value
  },

  watch: {
    value () {
      this.inputVal = this.value
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/style";

.base-input-wrapper {
  &.__full-width {
    display: flex;
    width: 100%;

    .base-input-container {
      width: 100%;
    }
  }

  &.__full-width .base-input-input {
    width: 100%;
    flex: 0 1 auto;
  }
}

.edit-variation.__price .base-input-number:first-child .base-input-container {
  border-right-color: transparent;

  &.__focused {
    border-right-color: $color-primary-focused;
  }
}

.base-input-container {
  box-sizing: border-box;
  transition: 0.3s ease;
  outline: none;
  min-height: 50px;
  border-radius: 8px;
  border: 1px solid $color-line;
  position: relative;

  &.__focused {
    border: 1px solid $color-primary-focused;
    box-shadow: 0 1px 3px 0 rgba($color-primary, 0.3);
  }

  &.__error {
    border: 1px solid $color-error;

    &:focus {
      border: 1px solid $color-error;
      box-shadow: 0 1px 3px 0 rgba($color-error, 0.3);
    }
  }

  &.__readonly {
    background: #EFF1F2;
    border-color: $color-disabled;
  }

  &.__readonly .base-input, &[readonly] {
    background: #EFF1F2;

    &::-webkit-input-placeholder {
      color: #8d9bbe;
    }

    ::-moz-placeholder {
      color: #8d9bbe;
    }

    :-ms-input-placeholder {
      color: #8d9bbe;
    }

    :-moz-placeholder {
      color: #8d9bbe;
    }
  }

  &--icon {
    margin: 0 0 0 20px;
  }
}

.base-input {
  position: absolute;
  width: 100%;
  left: 0;
  font-size: 14px;
  border: none;
  padding: 0 15px;
  min-height: 40px;
  box-sizing: border-box;

  &:focus {
    border: none;
    outline: none;
  }

  &-input {
    position: relative;
    min-width: 20px;
    height: 40px;
    overflow: hidden;
  }

  &-sign {
    color: $text-gray;
    font-size: 14px;
    padding-left: 15px;
  }

  &-errors {
    color: $color-error;
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
  }

  &-error {
    padding: 4px 0;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.base-input-container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.content-fit-emulation {
  padding: 0 15px 0 20px;
  font-size: 14px;
  line-height: 40px;
}

.input-with-label.__label-left .base-input-wrapper {
  position: relative;
}

.input-with-label.__label-left .base-input-errors {
  bottom: auto;
  top: 100%;
}
</style>
