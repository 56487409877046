import api from '@/api/api'
import axios from 'axios'
import i18n from '@shared/i18n.js'

const state = {
  token: localStorage.getItem('api_token') || '',
  email: localStorage.getItem('email') || '',
  newUserEmail: localStorage.getItem('newUserEmail') || '',
  emailConfirmed: localStorage.getItem('emailConfirmed') || '0',
  locale: localStorage.getItem('locale') || 'cn',
  registrationData: {
    email: localStorage.getItem('email') || '',
    contactName: '',
    password: '',
    isCompany: null,
  },
  profile: {
    contactName: '',
    contactEmail: '',
    companyName: '',
    contactPhone: '',
    address: '',
    city: '',
    country: '',
    birthdate: '',
    personalId: '',
    isCompany: null,
    llpMessage: '',
    accountNumber: '',
    bankName: '',
    bankAccountNumber: '',
    bankCountryCode: '',
    swiftCode: '',
    status: localStorage.getItem('status') || '',
    isWelcomePageShown: null,
    businessTypeCode: null,
    businessTypeNameCn: null,
  },
  businessTypes: [],
}

const getters = {
  profile: state => state.profile,
  profileStatus: state => state.profile.status,
  authorized: state => !!state.token,
  newUserEmail: state => state.newUserEmail,
  emailConfirmed: state => state.emailConfirmed,
  locale: state => state.locale,
  businessTypes: state => state.businessTypes,
}
const actions = {
  async registration ({ commit, dispatch }, user) {
    try {
      const response = await api.registration(user)
      commit('showHintNotification', {
        type: 'SUCCESS',
        title: 'Registration complete!',
        description: 'Now activate your account from email',
      })
      commit('setSuccessStatus')
      commit('setRegistrationData', user)
      commit('setNewUserEmail', user.email)
      return response.data
    } catch (error) {
      throw error
    }
  },
  async login ({ commit, dispatch }, payload) {
    try {
      commit('setLoadingStatus')
      commit('setEmail', payload.email)
      const response = await api.login(payload)
      const token = response.data.token
      commit('setToken', token)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
      throw error
    }
  },
  async activate ({ commit, dispatch }, payload) {
    try {
      commit('setLoadingStatus')
      const response = await api.activate(payload)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
    }
  },
  setAuthorizationHeader ({ commit }) {
    commit('setAuthorizationHeader')
  },
  logout ({ commit }) {
    commit('logout')
    commit('removeEmail')
    commit('removeStatus')
    commit('removeAuthorizationHeader')
  },
  async getProfile ({ commit, dispatch }) {
    try {
      commit('setAuthorizationHeader')
      commit('setLoadingStatus')
      const response = await api.getProfile()
      commit('setProfile', response.data)
      commit('setStatus', response.data.status)
      commit('setSuccessStatus')
      return response.data
    } catch (error) {
      dispatch('showError', error)
      localStorage.removeItem('api_token')
      commit('removeAuthorizationHeader')
    }
  },
  async updateProfile ({ commit, dispatch, state }) {
    try {
      commit('setLoadingStatus')
      commit('removeAllErrors')

      const response = await api.updateProfile({
        contactName: state.profile.contactName,
        contactEmail: state.profile.contactEmail,
        companyName: state.profile.companyName,
        contactPhone: state.profile.contactPhone,
        address: state.profile.address,
        city: state.profile.city,
        country: state.profile.country,
        birthdate: state.profile.birthdate,
        personalId: state.profile.personalId,
        businessTypeCode: state.profile.businessTypeCode,
        businessTypeNameCn: state.profile.businessTypeNameCn,
        isCompany: state.profile.isCompany,
      })

      commit('setProfile', response.data)
      commit('setSuccessStatus')
      commit('showHintNotification', {
        type: 'SUCCESS',
        title: 'Profile updated',
        description: 'Profile successfully updated',
      })
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
    }
  },
  async updatePaymentInformation ({ commit, state }) {
    try {
      commit('setLoadingStatus')
      commit('removeAllErrors')

      const response = await api.updateBankCredentials({
        bankAccountNumber: state.profile.bankAccountNumber,
        bankCountryCode: state.profile.bankCountryCode,
        bankName: state.profile.bankName,
        swiftCode: state.profile.swiftCode,
        bankCode: state.profile.bankCode,
        bankCnapsCode: state.profile.bankCnapsCode,
      })

      commit('setSuccessStatus')
      commit('showHintNotification', {
        type: 'SUCCESS',
        title: i18n.t('paymentDataSaved'),
      })
      return response.data
    } catch (error) {
      commit('setSuccessStatus')
      commit('showHintNotification', {
        title: i18n.t('copyErrorTitle'),
        type: 'ERROR',
      })
    }
  },
  async verifyProfile ({ commit, dispatch }, payload) {
    try {
      commit('setLoadingStatus')
      commit('removeAllErrors')
      const response = await api.verifyProfile(payload)
      commit('setSuccessStatus')
      commit('showHintNotification', {
        type: 'SUCCESS',
        title: 'Documents successfully uploaded',
        description: 'All profile actions will become available after moderation',
      })
      return response.data
    } catch (error) {
      dispatch('setAllErrors', error)
    }
  },
  clearProfile ({ commit }) {
    commit('clearProfile')
  },
  resendConfirmEmail ({ getters }) {
    api.resendConfirmEmail(getters.newUserEmail)
  },
  setEmailConfirmed ({ commit }) {
    commit('setEmailConfirmed', '1')
  },
  removeNewUser ({ commit }) {
    commit('removeNewUser')
  },
  async getBusinessTypes ({ commit, dispatch }) {
    try {
      const response = await api.getBusinessTypes()
      commit('setBusinessTypes', response)
      return response
    } catch (error) {
      dispatch('showError', error)
    }
  },
}

const mutations = {
  clearProfile (state) {
    state.profile = {
      email: '',
      accountNumber: '',
      address: '',
      bankAddress: '',
      bankName: '',
      companyName: '',
      contactName: '',
      personalId: '',
      swift: '',
    }
  },
  setLocale (state, locale) {
    state.locale = locale
    localStorage.setItem('locale', locale)
  },
  setProfile (state, data) {
    state.profile = data
  },
  setProfileValues (state, payload) {
    state.profile[payload.handle] = payload.value
  },
  setRegistrationData (state, user) {
    state.registrationData = user
  },
  setToken (state, token) {
    localStorage.setItem('api_token', token)
    state.token = token
  },
  logout (state) {
    localStorage.removeItem('api_token')
    state.token = ''
    state.profile = {}
  },
  setStatus (state, status) {
    localStorage.setItem('status', status)
  },
  removeStatus () {
    localStorage.removeItem('status')
  },
  setEmail (state, email) {
    localStorage.setItem('email', email)
    state.email = email
  },
  removeEmail (state) {
    localStorage.removeItem('email')
    state.email = ''
  },
  setAuthorizationHeader () {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('api_token')}`
  },
  removeAuthorizationHeader () {
    axios.defaults.headers.common['Authorization'] = null
  },
  setNewUserEmail (state, email) {
    localStorage.setItem('newUserEmail', email)
    state.newUserEmail = email
  },
  removeNewUser (state) {
    localStorage.removeItem('newUserEmail')
    localStorage.removeItem('emailConfirmed')
    state.newUserEmail = ''
    state.emailConfirmed = '1'
  },
  setEmailConfirmed (state, value) {
    state.emailConfirmed = value
    localStorage.setItem('emailConfirmed', value)
  },
  setBusinessType (state, type) {
    state.profile.businessTypeCode = type.code
    state.profile.businessTypeNameCn = type.nameCn
  },
  setBusinessTypes (state, typesList) {
    state.businessTypes = typesList
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
