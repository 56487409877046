export const CHN = 'CHN'
export const HKG = 'HKG'
export const NZL = 'NZL'
export const JPN = 'JPN'
export const USA = 'USA'
export const FRA = 'FRA'
export const GBR = 'GBR'
export const AUS = 'AUS'
export const KOR = 'KOR'
export const THA = 'THA'
export const DEU = 'DEU'
export const ESP = 'ESP'

export default [
  CHN,
  HKG,
  NZL,
  JPN,
  USA,
  FRA,
  GBR,
  AUS,
  KOR,
  THA,
  DEU,
  ESP,
]
