const state = {
  isGridView: true,
}

const mutations = {
  setGridView (state) {
    state.isGridView = true
  },
  setListView (state) {
    state.isGridView = false
  },
}

const actions = {
  setShowcaseView ({ commit }, view) {
    if (view === 'grid') {
      commit('setGridView')
    } else if (view === 'list') {
      commit('setListView')
    }
  },
}

export default {
  state,
  actions,
  mutations,
}
