const helpers = {
  /**
   * @param {object} error
   * @returns {boolean}
   */
  errorResponseExists (error) {
    const errorResponse = error.response
    return errorResponse &&
      (errorResponse?.data?.message != null || errorResponse?.data[0]?.message != null)
  },
  /**
   * @param {object} file
   * @callback cb
   */
  getMimeType (file, cb) {
    const mimes = [
      {
        mime: 'image/jpeg',
        pattern: [0xFF, 0xD8, 0xFF],
        mask: [0xFF, 0xFF, 0xFF],
      },
      {
        mime: 'image/png',
        pattern: [0x89, 0x50, 0x4E, 0x47],
        mask: [0xFF, 0xFF, 0xFF, 0xFF],
      },
    ]

    const check = (bytes, mime) => {
      for (let i = 0, l = mime.mask.length; i < l; ++i) {
        if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
          return false
        }
      }
      return true
    }
    const blob = file.slice(0, 4)
    const reader = new FileReader()
    reader.onloadend = e => {
      if (e.target.readyState === FileReader.DONE) {
        const bytes = new Uint8Array(e.target.result)
        for (let i = 0, l = mimes.length; i < l; ++i) {
          if (check(bytes, mimes[i])) {
            const isImage = true
            return cb(isImage)
          }
        }
        const isNotImage = false
        return cb(isNotImage)
      }
    }
    reader.readAsArrayBuffer(blob)
  },
  /**
   * @param {object} _this
   */
  checkCreateStepSelect (_this) {
    let { selectedBrand, selectedCategoryObject, selectedType } = _this
    const brandId = selectedBrand !== null ? selectedBrand.id : ''
    const brandName = selectedBrand !== null ? selectedBrand.label : ''
    const typeId = selectedType !== null ? selectedType.id : ''
    const typeName = selectedType !== null ? selectedType.label : ''
    const categoryId = (selectedCategoryObject !== null && selectedCategoryObject.id) ? selectedCategoryObject.id : ''
    const categoryName = (selectedCategoryObject !== null && selectedCategoryObject.title) ? selectedCategoryObject.title : ''
    if (brandId !== '' && categoryId !== '' && typeId !== '') {
      _this.$store.commit('setBrandCategoryStatus', true)
      _this.$store.commit('setModelSearchFields', { brandId, categoryId, typeId })
      _this.$store.commit('setBrandCategoryToNewModel', {
        brandId, categoryId, brandName, categoryName, typeId, typeName })
    } else {
      _this.$store.commit('setModelSearchFields', { brandId, categoryId, typeId })
      _this.$store.commit('setBrandCategoryStatus', false)
    }
  },
  /**
   * @param {object} _this
   * @param {string} searchText
   * @param {string} type - 'Brand', 'Category' or 'Type'
   */
  updateCreateSelectorOptions (_this, searchText, type) {
    if (searchText.length > 1) {
      return _this.$store.dispatch(`get${type}`, searchText).then(() => {
        if (type === 'Category') {
          _this.categoryList = []
        } else if (type === 'Brand') {
          _this.brandList = []
        } else if (type === 'Type') {
          _this.typeList = []
        }
        const types = type === 'Category' ? 'categories' : `${type.toLowerCase()}s`
        _this[`${types}`].forEach(item => {
          _this[`${type.toLowerCase()}List`].push({ id: item.id, label: item.name })
        })
      })
    }
  },
  /**
   * @param {object} obj
   * @returns {boolean}
   */
  isEmptyObject (obj) {
    if (Object.keys(obj).length === 0) {
      return true
    }
  },

  getDate (date) {
    let day = date.getUTCDate()

    if (day < 10) {
      day = `0${day}`
    }

    let month = date.getUTCMonth() + 1

    if (month < 10) {
      month = `0${month}`
    }

    let year = date.getFullYear()

    return `${day}.${month}.${year}`
  },

  getTime (date) {
    let hours = date.getUTCHours()

    if (hours < 10) {
      hours = `0${hours}`
    }

    let minutes = date.getUTCMinutes()

    if (minutes < 10) {
      minutes = `0${minutes}`
    }

    return `${hours}:${minutes}`
  },

  debounce (fn, delay) {
    let timeout = null

    return function () {
      clearTimeout(timeout)

      const args = arguments
      const context = this

      timeout = setTimeout(function () {
        fn.apply(context, args)
      }, delay)
    }
  },

  productAttributes (productsOwn, productsAvailable) {
    const attributesGeneral = []
    let attributesUniqueKeys = []
    const attributesAll = []
    let productsWithAttributes = 0
    // collect own products attributes
    if (productsOwn?.length > 0) {
      for (const product of productsOwn) {
        if (!product?.attributes || product?.attributes?.length === 0) continue
        productsWithAttributes++
        for (const attribute of product?.attributes) {
          attributesAll.push({ key: attribute.key.name, value: attribute.value.name })
        }
      }
    }
    // collect available products attributes
    if (productsAvailable?.length > 0) {
      for (const product of productsAvailable) {
        if (!product?.attributes || product?.attributes?.length === 0) continue
        productsWithAttributes++
        for (const attribute of product?.attributes) {
          attributesAll.push({ key: attribute.key.name, value: attribute.value.name })
        }
      }
    }
    // attributes separating
    for (const attribute of attributesAll) {
      const filtered = attributesAll.filter(item => {
        return item.key === attribute.key && item.value === attribute.value
      })
      if (filtered.length === productsWithAttributes) {
        const filtered = attributesGeneral.filter(item => {
          return item.key === attribute.key && item.value === attribute.value
        })
        if (filtered.length === 0) attributesGeneral.push(attribute)
      } else {
        attributesUniqueKeys.push(attribute.key)
      }
    }
    // only unique keys
    attributesUniqueKeys = attributesUniqueKeys.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    return {
      general: attributesGeneral,
      uniqueKeys: attributesUniqueKeys,
      productsWithAttributes,
    }
  },
}
export default helpers
