<template>
  <base-button
    :warn="warn"
    :disabled="disabled"
    :transparent="transparent"
    :secondary="secondary"
    @click="$emit('click')"
  >
    <svg-icon
      v-if="icon"
      :icon-name="icon"
      class-name="icon"
      :style="`width: ${iconSize}px; height: ${iconSize}px;`"
    />
    {{value}}
  </base-button>
</template>

<script>
import SvgIcon from 'shared/src/components/SvgIcon'
import BaseButton from './BaseButton'

export default {
  name: 'simple-button',
  components: {
    SvgIcon,
    BaseButton,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconSize: {
      type: Number,
      default: 10,
    },
    transparent: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    warn: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/style";

.icon {
  margin-right: 8px;
}

.model-card-body .button .icon,
.product .button .icon {
  stroke: $color-primary;
  transition: 0.2s all ease;
}
.model-card-body .button:hover .icon,
.product .button:hover .icon{
  stroke: #fff;
}

.model-card-body .base-button--disabled .icon,
.product .base-button--disabled .icon {
  stroke: $color-disabled;
}
</style>
