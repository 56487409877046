import api from '@/api/api'

const state = {
  currentPage: 1,
  totalPages: 20,
  perPage: 20,
  ordersStatuses: [],
  orders: [],
  order: {},
  pagination: {},
}

const actions = {
  async getOrders ({ commit, dispatch }, payload) {
    try {
      const response = await api.getOrders(payload)
      commit('setOrders', response?.data?.items)
      commit('setOrdersPagination', response?.data?.pagination)
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async getOrder ({ commit, dispatch }, payload) {
    try {
      const response = await api.getOrder(payload)
      commit('setOrder', response.data.items[0])
      return response.data
    } catch (error) {
      dispatch('showError', error)
      throw error
    }
  },

  async getStatusesWithCount ({ commit, dispatch }) {
    try {
      const response = await api.getStatuses()
      commit('setStatuses', response.data)
      return response.data
    } catch (error) {
      throw error
    }
  },

  async changeOrderStatus ({ commit, dispatch, state }, { orderId, status }) {
    try {
      commit('setLoadingStatus')
      const response = await api.changeOrderStatus(orderId, status)
      return response.data
    } catch (error) {
      throw error
    } finally {
      commit('setSuccessStatus')
    }
  },

  async loadShippingLabel ({ commit, dispatch }, { orderId, shipmentNumber }) {
    try {
      const response = await api.loadShippingLabel(orderId)

      const data = window.URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = data
      link.download = `shipment-label-${shipmentNumber}.pdf`
      link.click()
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)

      return response.data
    } catch (error) {
      throw error
    }
  },

  ordersPageChanged ({ dispatch, state }, { newPage, status }) {
    dispatch('getOrders', {
      status,
      page: newPage,
      perPage: state.pagination.per_page,
    })
  },

  ordersPageSizeChanged ({ commit, dispatch }, { perPage, status }) {
    dispatch('getOrders', {
      status,
      page: 1,
      perPage,
    })
  },
}

const mutations = {
  setOrders (store, orders) {
    store.orders = orders
  },

  setOrder (store, order) {
    store.order = order
  },

  setOrdersPagination (store, pagination) {
    store.pagination = pagination
  },

  setStatuses (store, statuses) {
    store.ordersStatuses = statuses || []
  },

  decrementStatusCount (store, statusId) {
    store.ordersStatuses = store.ordersStatuses.map(status => {
      if (status.status === statusId) {
        status.count = status.count - 1
      }
      return status
    })
  },

  removeOrderFromOrders (store, orderId) {
    store.orders = store.orders.filter(order => order.id !== orderId)
  },
}

export default {
  state,
  actions,
  mutations,
}
