<template>
  <header class="header">
    <the-loader/>
    <the-overlay/>
    <div class="wrapper">
      <div class="header__left">
        <router-link class="link_logo" to="/">
          <img class="header__logo" alt="header__logo" src="@/assets/Logo.svg">
        </router-link>
        <div class="header__navigation" v-if="authorized">
          <router-link
            class="header__link h4"
            :active-class="'header__link_active'"
            :to="{ name: 'goods', params: { status: 1 }}">
            {{ $t('goods') }}
          </router-link>
          <router-link
            class="header__link h4"
            :active-class="'header__link_active'"
            :to="{ name: 'orders', params: { status: 1 }}"
          >
            {{ $t('orders') }}
          </router-link>
          <router-link
            class="header__link h4"
            :active-class="'header__link_active'"
            to="/billing">
            <span>{{ $t('billing') }}</span>
          </router-link>
        </div>
      </div>
      <div class="header__right">
        <div
          v-if="showHeaderNotification && authorized"
          class="header__notification"
          :class="headerNotificationClasses"
          @click="goToProfile"
        >
          <img
            :src="require(`@/assets/${headerNotificationIcon}.svg`)"
            class="header__notification-icon"
          />
          <div>
            {{headerNotificationText}}
            <p v-if="profileRejected">{{$t('rejectedAccountNotificationSubText')}}</p>
          </div>
        </div>
        <router-link
          v-if="true"
          to="/help-center"
          class="help-center-link header__link"
        >
          <img
            class="help-center-link--icon"
            src="@/assets/question.svg"
          />
          {{ $t('helpCenterLink') }}
        </router-link>
        <the-language-selector/>
        <div class="header__login login" v-if="!authorized">
          <SimpleButton
            :value="$t('login')"
            transparent
            @click="$router.push('/')"
          />
          <SimpleButton
            transparent
            :value="$t('register')"
            @click="$router.push('/registration')"
          />
        </div>
        <div class="header__account" v-if="authorized">
          <div
            class="header__user user"
            :class="isOpen ? 'user_active' : ''"
            v-click-outside="closeMenu"
            @click="toggleMenu">
            <div class="user__icon">
              <svg-icon id="user" icon-name="user" width="28px" height="28px"/>
            </div>
            <div class="user__data">
              <div class="user__email">
                {{ email }}
              </div>
              <!--div class="header__audit" v-if="profileApproved">
                <svg-icon class="user-icon verified" icon-name="checkmark-filled"/>
                <div class="header__audit-text">{{ $t('profileApproved') }}</div>
              </div-->
            </div>

            <div class="user___list">
              <router-link class="user__list-item link_user" to="/profile">{{$t('profile')}}</router-link>
              <div
                class="user__list-item"
                @click="logout">{{$t('logout')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TheLanguageSelector from '@/components/ui/selectors/TheLanguageSelector.vue'
import TheLoader from '@/components/ui/TheLoader'
import TheOverlay from '@/components/ui/TheOverlay'
import vClickOutside from 'click-outside-vue3'
import { mapState } from 'vuex'
import {
  PROFILE_STATUS_NEW,
  PROFILE_STATUS_ENABLED,
  PROFILE_STATUS_PROCESSING,
  PROFILE_STATUS_APPROVED,
  PROFILE_STATUS_FAILED,
  PROFILE_STATUS_BLOCKED
} from '@/helpers/consts/profile-statuses'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'

export default {
  name: 'TheHeader',
  components: {
    TheLanguageSelector,
    TheLoader,
    TheOverlay,
    SimpleButton,
  },
  data () {
    return {
      isOpen: false,
    }
  },
  mounted () {
    this.$i18n.locale = this.$store.state.user.locale
  },
  computed: {
    ...mapState({ email: state => state.user.email }),
    ...mapState({ authorized: state => !!state.user.token }),
    ...mapState({ profileStatus: state => state.user.profile.status }),
    profileApproved () {
      return this.profileStatus === PROFILE_STATUS_APPROVED
    },
    profileRejected () {
      return this.profileStatus === PROFILE_STATUS_FAILED
    },
    showHeaderNotification () {
      switch (this.profileStatus) {
        case PROFILE_STATUS_APPROVED:
        case PROFILE_STATUS_BLOCKED:
          return false
        default:
          return true
      }
    },
    headerNotificationIcon () {
      switch (this.profileStatus) {
        case PROFILE_STATUS_NEW:
        case PROFILE_STATUS_ENABLED:
          return 'dollar'
        case PROFILE_STATUS_PROCESSING:
          return 'clock'
        case PROFILE_STATUS_FAILED:
          return 'error'
        default:
          return ''
      }
    },
    headerNotificationText () {
      switch (this.profileStatus) {
        case PROFILE_STATUS_NEW:
        case PROFILE_STATUS_ENABLED:
          return this.$t('newAccountNotification')
        case PROFILE_STATUS_PROCESSING:
          return this.$t('pendingAccountNotification')
        case PROFILE_STATUS_FAILED:
          return this.$t('rejectedAccountNotification')
        default:
          return ''
      }
    },
    headerNotificationClasses () {
      return { 'header__notification--error': this.profileStatus === PROFILE_STATUS_FAILED }
    },
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    },
    closeMenu () {
      this.isOpen = false
    },
    searchEnter (e) {
      if (e.keyCode === 13) {
        this.search()
      }
    },
    search () {
      alert('search')
    },
    async logout () {
      await this.$store.dispatch('logout')
      if (this.$router.currentRoute.path !== '/') {
        await this.$router.push('/')
      }
    },
    goToProfile () {
      if (this.$router.currentRoute.path !== '/profile') {
        this.$router.push('/profile')
      }
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
}
</script>

<style lang="scss" scoped>
.link_user {
  text-decoration: none;
  color: #333;
  display: block;
}
.header {
  height: 60px;

  &__notification {
    max-width: 174px;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
    gap: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    margin-right: 12px;
    cursor: pointer;
    background-color: $color-message-bg;

    &--error {
      background-color: $color-error-secondary;
      color: $color-white;
    }

    p {
      font-weight: 400;
    }
  }

  &__notification-icon {
    width: 24px;
    height: 24px;
  }
}

.wrapper {
  display: flex;
  height: 60px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.link_logo {
  margin: 9px 15px 0 0;
}

.header__right, .header__left {
  display: flex;
  height: 60px;
  align-items: center;
}

.header__navigation-wrap {
  margin-right: auto;
}

.header__account {
  display: flex;
  align-items: center;
}

.header__navigation {
  list-style: none;
  display: flex;
}

.header__login {
  display: flex;
  gap: 8px;
}

.login__link {
  padding: 12px 24px;
  border-radius: 24px;
  border: 1px solid $color-primary;
  color: $color-primary;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  @include headerHoverActive;
}

.login__login {
  margin-right: 10px;
}

.header__link {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  padding: 8px 16px;
  border-radius: 35px;
  transition: 0.3s background;

  &:hover,
  &_active {
    background-color: $color-grey;
  }
}

.header__link_active {
  &:after {
    position: absolute;
    content: "";
    background: $color-white;
    left: 0;
    bottom: -18px;
    height: 2px;
    width: 100%;
    transition: 0.3s background;
  }
  &:hover:after {
    background: $color-header_hover;
  }
}

.header__search-input {
  position: relative;
  width: 340px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid $color-primary;
  background: $color-white;
  box-sizing: border-box;
  outline: none;
  padding-left: 20px;
  padding-right: 40px;
  line-height: 34px;
  font-size: 12px;
  color: #999;
}

.header__search {
  position: relative;
  width: 340px;
  margin-right: 20px;
}

.search-input_search {
  position: absolute;
  right: 21px;
  top: 9.5px;
  cursor: pointer;
}

#search-icon {
  position: relative;
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  color: #000;
  margin-right: 17px;
  cursor: pointer;
}

.user__icon {
  width: 38px;
  height: 38px;
  background: $color-white;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#user {
  width: 24px;
  height: 24px;
  fill: $color-primary;
}

.user__email {
  position: relative;
  font-size: 14px;
  margin-bottom: 4px;

  &:after {
    position: absolute;
    content: "";
    background: url("~@/assets/arrow.svg");
    width: 24px;
    height: 24px;
    right: -24px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: 0.3s ease;
  }
}

.user___list {
  position: absolute;
  top: 40px;
  left: 40px;
  background: $color-white;
  display: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.user__list-item {
  color: #000;
  padding: 0 17px;
  text-align: left;
  min-height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s ease;

  &:hover {
    background: $color-hover;
  }
}

.user_active {
  .user___list {
    display: block;
  }

  .user__email:before {
    transform: rotate(180deg);
  }
}

.header__audit {
  display: flex;
  align-items: center;
}

.user-icon {
  margin-right: 2px;
  width: 14px;
  height: 14px;
  fill: $color-primary;
}

.header__audit-text {
  font-size: 12px;
  font-weight: 700;
  color: $color-primary;
}

.help-center-link {
  display: inline-flex;
  align-items: center;
  color: $color-primary;

  &--icon {
    color: $color-primary;
    margin-right: 4px;
    transition: 0.3s ease opacity;
  }

  &:hover &--icon {
    opacity: 0.5;
  }
}
</style>
